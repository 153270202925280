import Button from "@/component-library/components/buttons/button/Button";
import { wideLocationCardAlternative1Style } from "@/component-library/components/cards/location-cards/wide-location-card-alternative-1/WideLocationCardAlternative1.css";
import {
    wideLocationCardContentHeaderStyle,
    wideLocationCardContentHeaderTitleStyle,
    wideLocationCardContentStyle,
    wideLocationCardImageStyle,
    wideLocationCardLocationDescriptionStyle,
    wideLocationCardLocationNameStyle,
    wideLocationCardOperatorNameStyle,
    wideLocationCardPricingStyle,
    wideLocationCardRatingStyle,
} from "@/component-library/components/cards/location-cards/wide-location-card-shared-styles.css";
import { ConvertedCurrencyComponent } from "@/component-library/components/currency/converted-currency/ConvertedCurrencyComponent";
import MapMarkerIcon from "@/component-library/components/icons/MapMarkerIcon";
import Separator from "@/component-library/components/layout/separator/Separator";
import RemoteImage from "@/component-library/components/media/remote-image/RemoteImage";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { skeletonStyle } from "@/component-library/components/skeleton-style.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { theme } from "@/component-library/themes/theme.css";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React from "react";

interface WideLocationCardAlternative1SkeletonProps {}

const WideLocationCardAlternative1Skeleton: React.FC<
    WideLocationCardAlternative1SkeletonProps
> = () => {
    return (
        <Flex
            direction={"row"}
            gap={0}
            className={clsx(wideLocationCardAlternative1Style)}
        >
            <div
                className={wideLocationCardImageStyle}
                style={assignInlineVars({
                    flex: `0 0 23em`,
                })}
            >
                <RemoteImage
                    fallbackCaption={"Image of the location"}
                    imageResolution={{
                        widthInPx: 400,
                        heightInPx: 400 / (16 / 9),
                    }}
                    pictureData={undefined}
                    keepInLoadingState
                />
            </div>
            <Flex
                direction={"column"}
                className={wideLocationCardContentStyle}
                style={assignInlineVars({
                    flex: `0 0 24.5em`,
                })}
                alignItems={"start"}
            >
                <Flex
                    direction={"row"}
                    className={wideLocationCardContentHeaderStyle}
                >
                    <div className={wideLocationCardContentHeaderTitleStyle}>
                        <div
                            className={clsx(
                                skeletonStyle,
                                wideLocationCardOperatorNameStyle
                            )}
                        >
                            Loading operator...
                        </div>
                        <Flex
                            direction="row"
                            alignItems="center"
                            gap={Magnitudes.spacingInRem.xxxs}
                            style={assignInlineVars({
                                overflow: `hidden`,

                                marginTop: `-0.25em`,

                                whiteSpace: `nowrap`,
                            })}
                            className={skeletonStyle}
                        >
                            <MapMarkerIcon
                                pathProps={{
                                    style: assignInlineVars({
                                        fill: "transparent",
                                    }),
                                }}
                                style={assignInlineVars({
                                    flex: `1 0 auto`,
                                    display: `block`,
                                })}
                            />
                            <div
                                className={clsx(
                                    wideLocationCardLocationNameStyle
                                )}
                                style={assignInlineVars({
                                    color: "transparent",
                                })}
                            >
                                Loading location name...
                            </div>
                        </Flex>
                    </div>
                </Flex>

                <div
                    className={clsx(
                        wideLocationCardLocationDescriptionStyle,
                        skeletonStyle
                    )}
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    ac velit quis felis ultricies.
                </div>
            </Flex>
            <Separator variant="verticalLight" />
            <Flex
                direction={"column"}
                className={wideLocationCardContentStyle}
                alignItems={"stretch"}
                justifyContent={"end"}
            >
                <Flex
                    direction={"row"}
                    justifyContent={"end"}
                    style={assignInlineVars({
                        color: theme.colors.semantic.interactive.default,
                        fontWeight: `500`,
                        fontSize: `${Magnitudes.fontInRem.m}rem`,
                        marginBottom: `auto`,
                    })}
                >
                    <div className={skeletonStyle}>Very good</div>
                    <div
                        className={clsx(
                            skeletonStyle,
                            wideLocationCardRatingStyle
                        )}
                    >
                        <VisuallyHidden>Loading rating...</VisuallyHidden>
                    </div>
                </Flex>
                <Flex
                    direction={"column"}
                    alignItems={"flex-end"}
                    className={clsx(
                        wideLocationCardPricingStyle,
                        skeletonStyle
                    )}
                    gap={0}
                >
                    <div className={skeletonStyle}>Starting from</div>
                    <div>
                        <ConvertedCurrencyComponent
                            amount={0}
                            currency={"USD"}
                        />
                    </div>
                </Flex>
                <Button className={skeletonStyle}>Book now</Button>
            </Flex>
        </Flex>
    );
};

export default WideLocationCardAlternative1Skeleton;
