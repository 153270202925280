import { useDiscoverMapStore } from "@/app/(main)/discover/[[...params]]/(application)/useDiscoverMapStore";
import { HostLocationPageApi } from "@/app/(main)/discover/host/[hostLocationId]/pageApi";
import { hasValue } from "@/common/utilities/hasValue";
import { isNotEmptyStringOrMissingValue } from "@/common/utilities/isNotEmptyStringOrMissingValue";
import SmallLocationCardAlternative1 from "@/component-library/components/cards/location-cards/small-location-card-alternative-1/SmallLocationCardAlternative1";
import SmallLocationCardAlternative1Skeleton from "@/component-library/components/cards/location-cards/small-location-card-alternative-1/SmallLocationCardAlternative1Skeleton";
import { useSingleHostLocation } from "@/features/host-locations/application/useSingleHostLocation";
import { RatingsType } from "@/features/host-locations/domain/entities/RatingsSchema";
import { TSimplifiedHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/SimplifiedHostLocationWithWorkspaceGroupsSchema";
import { WezooResourceTypeSchema } from "@/features/resource/resource-types/shared/WezooResourceType";
import { useSearchParams } from "next/navigation";
import React, { Fragment, useMemo } from "react";

interface DiscoverListLocationCardProps {
    hostLocationWithWorkspaceGroups: TSimplifiedHostLocationWithWorkspaceGroupsSchema;
    doNotChangeHighlightedHostLocationIdOnHover?: boolean;
    priority?: boolean;
}

const DiscoverMapListLocationCard: React.FC<DiscoverListLocationCardProps> = ({
    hostLocationWithWorkspaceGroups: _hostLocation,
    doNotChangeHighlightedHostLocationIdOnHover = false,
    priority,
}) => {
    const queryParams = useSearchParams();

    const { data: hostLocation, isLoading: isHostLocationLoading } =
        useSingleHostLocation(_hostLocation.location.id);

    const setHighlightedHostLocationId = useDiscoverMapStore(
        (state) => state.setHighlightedHostLocationId
    );

    const locationDetails = useMemo(() => {
        if (!hostLocation) {
            return null;
        }

        return {
            operatorName: isNotEmptyStringOrMissingValue(
                hostLocation.location.operator?.name
            )
                ? hostLocation.location.operator.name
                : undefined,
            locationTitle: hostLocation.location.name,
            pictures: hostLocation.location.pictures,
            currencyCode: hostLocation.location.currency.code,
        };
    }, [hostLocation]);

    const numberOfMatchingRooms = useMemo(() => {
        // We count all rooms, and if there are hotdesks, we add one to the count.

        const hasMatchingHotdesks =
            _hostLocation.workspace_groups.filter(
                (group) =>
                    group.type === WezooResourceTypeSchema.enum.HOTDESK &&
                    group.isMatch
            ).length > 0;

        return (
            _hostLocation.workspace_groups.reduce((acc, group) => {
                if (
                    group.type === WezooResourceTypeSchema.enum.ROOM &&
                    group.isMatch
                ) {
                    return acc + 1;
                }

                return acc;
            }, 0) + (hasMatchingHotdesks ? 1 : 0)
        );
    }, [_hostLocation.workspace_groups]);

    const pricing = useMemo(() => {
        const rawPricing =
            _hostLocation.location.pricingInformation?.lowestPrice;

        if (!rawPricing) {
            return undefined;
        }

        return {
            hourlyPriceWithoutVat: rawPricing.hourly.totalPrice,
            totalPriceWithoutVat: rawPricing.total?.totalPrice,
        };
    }, [_hostLocation.location.pricingInformation?.lowestPrice]);

    const overallRating = useMemo(() => {
        const rawOverallRating = hostLocation?.location.ratings
            ?.filter((rating) => rating.type === RatingsType.overall)
            .at(0);

        if (!hasValue(rawOverallRating)) {
            return null;
        }

        return {
            rating: rawOverallRating.value * 10,
            numberOfReviews: rawOverallRating.amount,
        };
    }, [hostLocation?.location.ratings]);

    const urlToLocationPage = useMemo(() => {
        if (!hasValue(hostLocation?.location.slug)) {
            return "#";
        }

        return HostLocationPageApi.getRoute({
            hostLocationId: hostLocation.location.slug,
            params: Object.fromEntries(queryParams.entries()),
        });
    }, [hostLocation?.location.slug, queryParams]);

    if (
        isHostLocationLoading ||
        !hasValue(hostLocation) ||
        !hasValue(locationDetails)
    ) {
        return (
            <SmallLocationCardAlternative1Skeleton key="discover-map-list-location-card-skeleton" />
        );
    }

    if (!hasValue(pricing)) {
        return <Fragment key="has-no-pricing" />;
    }

    return (
        <SmallLocationCardAlternative1
            options={{
                locationPageHref: urlToLocationPage,
                ctaLabel: `View ${numberOfMatchingRooms} matching options`,
                dealLabel: _hostLocation.location.discounts.length
                    ? "DEAL"
                    : undefined,
                locationDetails: {
                    name: locationDetails.locationTitle,
                    operator: {
                        name: locationDetails.operatorName,
                    },
                    currency: {
                        code: locationDetails.currencyCode,
                    },
                },
                picture: {
                    pictureData: locationDetails.pictures.at(0),
                },
                rating: overallRating?.rating.toFixed(1),
                pricing,
                priority,
            }}
            onMouseEnter={
                doNotChangeHighlightedHostLocationIdOnHover
                    ? undefined
                    : () =>
                          setHighlightedHostLocationId(hostLocation.location.id)
            }
            onMouseLeave={
                doNotChangeHighlightedHostLocationIdOnHover
                    ? undefined
                    : () => setHighlightedHostLocationId(null)
            }
        />
    );
};

export default DiscoverMapListLocationCard;
