import { mapboxSessionToken } from "@/component-library/components/geo-search/mapboxSessionToken";
import { WezooMapConfig } from "@/configs/discover/WezooMapConfig";
import { useEffect, useState } from "react";

export interface GeoRetrieveResult {
    id: string;
    name: string;
    coordinates: [number, number];
    bbox?: [number, number, number, number];
}

export const useMapboxRetrieveById = (id?: string) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [result, setResult] = useState<GeoRetrieveResult | null>(null);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (!id) {
            setResult(null);
            return;
        }

        const fetchResults = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `https://api.mapbox.com/search/searchbox/v1/retrieve/${id}?session_token=${mapboxSessionToken}&access_token=${WezooMapConfig.accessToken}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch results");
                }
                const data = (await response.json()) as {
                    type: "FeatureCollection";
                    features: {
                        id: string;
                        geometry: {
                            coordinates: [number, number];
                        };
                        properties: {
                            name: string;
                            address: string;
                            feature_type: string;
                            bbox?: [number, number, number, number];
                        };
                    }[];
                };
                if (data.features.length === 0) {
                    setResult(null);
                }
                const feature = data.features[0];
                setResult({
                    id: feature.id,
                    name: feature.properties.name,
                    coordinates: feature.geometry.coordinates,
                    bbox: feature.properties.bbox,
                });
            } catch (e: any) {
                if (typeof e === "string") {
                    setError(new Error(e));
                } else {
                    setError(e);
                }
            } finally {
                setLoading(false);
            }
        };

        void fetchResults();
    }, [id]);

    return { result, loading, error };
};
