import { smallLocationCardAlternative2Style } from "@/component-library/components/cards/location-cards/small-location-card-alternative-2/SmallLocationCardAlternative2.css";
import {
    smallLocationCardContentHeaderTitleStyle,
    smallLocationCardContentStyle,
    smallLocationCardImageOverlayContentItemStyle,
    smallLocationCardImageOverlayContentStyle,
    smallLocationCardImageStyle,
    smallLocationCardLocationNameStyle,
    smallLocationCardOperatorNameStyle,
    smallLocationCardPricingStyle,
    smallLocationCardRatingStyle,
} from "@/component-library/components/cards/location-cards/small-location-card-shared-styles.css";
import MapMarkerIcon from "@/component-library/components/icons/MapMarkerIcon";
import RemoteImage from "@/component-library/components/media/remote-image/RemoteImage";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import {
    skeletonInvisibleTextStyle,
    skeletonStyle,
} from "@/component-library/components/skeleton-style.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { theme } from "@/component-library/themes/theme.css";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React from "react";

interface SmallLocationCardAlternative2SkeletonProps {}

const SmallLocationCardAlternative2Skeleton: React.FC<
    SmallLocationCardAlternative2SkeletonProps
> = () => {
    return (
        <div className={smallLocationCardAlternative2Style}>
            <div className={smallLocationCardImageStyle}>
                <RemoteImage
                    fallbackCaption={"Image of the location"}
                    imageResolution={{
                        widthInPx: 400,
                        heightInPx: 300,
                    }}
                    pictureData={undefined}
                    keepInLoadingState
                />
                <div className={smallLocationCardImageOverlayContentStyle}>
                    <div className={smallLocationCardRatingStyle}>
                        <VisuallyHidden>Loading rating...</VisuallyHidden>
                    </div>
                    <div
                        className={clsx(
                            skeletonStyle,
                            smallLocationCardImageOverlayContentItemStyle
                        )}
                    >
                        Loading rooms...
                    </div>
                </div>
            </div>
            <div className={smallLocationCardContentStyle}>
                <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={Magnitudes.spacingInRem.xxs}
                >
                    <div className={smallLocationCardContentHeaderTitleStyle}>
                        <div
                            className={clsx(
                                skeletonStyle,
                                smallLocationCardOperatorNameStyle
                            )}
                        >
                            Loading operator...
                        </div>
                        <Flex
                            direction="row"
                            alignItems="center"
                            gap={Magnitudes.spacingInRem.xxxs}
                            style={assignInlineVars({
                                overflow: `hidden`,

                                marginTop: `-0.25em`,

                                whiteSpace: `nowrap`,
                            })}
                        >
                            <MapMarkerIcon
                                pathProps={{
                                    style: assignInlineVars({
                                        fill: theme.colors.semantic.interactive
                                            .default,
                                    }),
                                }}
                                style={assignInlineVars({
                                    flex: `1 0 auto`,
                                    display: `block`,
                                })}
                            />
                            <div
                                className={clsx(
                                    skeletonStyle,
                                    smallLocationCardLocationNameStyle
                                )}
                            >
                                Loading location...
                            </div>
                        </Flex>
                    </div>

                    <div
                        className={clsx(
                            smallLocationCardPricingStyle,
                            skeletonStyle
                        )}
                    >
                        <div className={skeletonInvisibleTextStyle}>
                            Starting from
                        </div>
                        <div className={skeletonInvisibleTextStyle}>
                            <VisuallyHidden>Price is </VisuallyHidden>
                            loading...
                        </div>
                    </div>
                </Flex>
            </div>
        </div>
    );
};

export default SmallLocationCardAlternative2Skeleton;
