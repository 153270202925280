"use client";

import { discoverFilteringToolbarStyle } from "@/app/(main)/discover/(layout)/(components)/(discover-app-bar)/(filtering)/DiscoverFilteringToolbarStyles.css";
import {
    discoverAppBarBottomContentStyle,
    discoverAppBarBottomInnerContentStyle,
    discoverAppBarFloaterStyle,
} from "@/app/(main)/discover/(layout)/(components)/(discover-app-bar)/DiscoverAppBar.css";
import {
    mapQueryStateLatitudeKey,
    mapQueryStateLongitudeKey,
} from "@/app/(main)/discover/[[...params]]/(components)/(map)/mapQueryStateKeys";
import { discoverMapListSplitViewMobileMaxWidthInPx } from "@/app/(main)/discover/[[...params]]/(components)/discover-map-constants";
import Button from "@/component-library/components/buttons/button/Button";
import GeoSearchInput from "@/component-library/components/geo-search/GeoSearchInput";
import { GeoRetrieveResult } from "@/component-library/components/geo-search/useMapboxRetrieveById";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { capacityFilter } from "@/features/filtering/filters/capacity-filter/CapacityFilter";
import { dateTimeFilter } from "@/features/filtering/filters/date-time-filter/dateTimeFilter";
import { resourceTypeFilter } from "@/features/filtering/filters/resource-type-filter/resourceTypeFilter";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import { parseAsFloat, useQueryState } from "nuqs";
import { FC, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

type DiscoverMapHeaderProps = {
    query?: string;
    placeholder?: string;
    showResourceTypeFilter: boolean;
    onSearch: () => void;
    setSelectedLocation: (location: GeoRetrieveResult | null) => void;
};

export const DiscoverAppBarSearchComponent: FC<DiscoverMapHeaderProps> = ({
    query: q,
    placeholder,
    showResourceTypeFilter,
    onSearch,
    setSelectedLocation,
}) => {
    //const [searchPlaceholder] = useState<string | undefined>(placeholder);
    //reportInfo("DiscoverAppBarSearchComponent", { searchPlaceholder });
    // const { setParams } = useApplyableParameterStore();
    const geoSearchInputRef = useRef<null | {
        closeDropdown: () => void;
    }>(null);
    const [query, setQuery] = useState<string>(q || "");
    const [longitude] = useQueryState(mapQueryStateLongitudeKey, parseAsFloat);
    const [latitude] = useQueryState(mapQueryStateLatitudeKey, parseAsFloat);

    const isMobile = useMediaQuery(
        `screen and (max-width: ${discoverMapListSplitViewMobileMaxWidthInPx}px)`,
        { initializeWithValue: false }
    );

    return (
        <Flex
            direction={"column"}
            className={clsx(discoverAppBarBottomContentStyle)}
            alignItems={"start"}
        >
            {showResourceTypeFilter && (
                <Flex
                    direction={"row"}
                    alignItems={"stretch"}
                    className={clsx(discoverAppBarBottomInnerContentStyle)}
                >
                    {resourceTypeFilter.urlSyncedComponent}
                </Flex>
            )}
            <Flex
                // swap to column layout on small screens
                direction={"row"}
                alignItems={"stretch"}
                className={discoverAppBarFloaterStyle}
            >
                <div
                    className={clsx(discoverFilteringToolbarStyle)}
                    style={{
                        alignItems: "stretch",
                        ...(isMobile
                            ? {
                                  flexDirection: "column",
                                  gap: "1rem",
                              }
                            : {}),
                    }}
                >
                    <GeoSearchInput
                        ref={geoSearchInputRef}
                        placeholder={placeholder}
                        query={query}
                        proximity={
                            latitude && longitude
                                ? { latitude, longitude }
                                : undefined
                        }
                        setQuery={setQuery}
                        onSubmitItem={(result) =>
                            setSelectedLocation(result ?? null)
                        }
                        onSelection={(result) =>
                            setSelectedLocation(result ?? null)
                        }
                    />
                    {dateTimeFilter.applyableComponent}
                    {capacityFilter.applyableComponent}
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            onSearch();
                        }}
                        variant={"smallFilled"}
                        style={assignInlineVars({
                            fontSize: `${Magnitudes.fontInRem.m}rem`,
                        })}
                    >
                        Search
                    </Button>
                </div>
            </Flex>
        </Flex>
    );
};
