"use client";

import { OutOfProviderError } from "@/common/domain/errors/development/OutOfProviderError";
import React, { createContext, ReactNode, useContext } from "react";
import { createStore, StoreApi, useStore } from "zustand";

interface SplitViewStoreState {
    isSidebarExpanded: boolean;
    bottomComponent: ReactNode | null;
}

const initialState: SplitViewStoreState = {
    isSidebarExpanded: true,
    bottomComponent: null,
};

interface SplitViewStoreActions {
    setSidebarExpanded: (expanded: boolean) => void;
    showAtBottom: (component: ReactNode) => void;
    hideComponentShownAtBottom: () => void;
}

export type SplitViewStore = SplitViewStoreState & SplitViewStoreActions;

const SplitViewStoreContext = createContext<StoreApi<SplitViewStore> | null>(
    null
);

interface SplitViewStoreProviderProps {
    children?: ReactNode;
}

export const SplitViewStoreProvider: React.FC<SplitViewStoreProviderProps> = ({
    children,
}) => {
    const store = createStore<SplitViewStore>((set) => ({
        ...initialState,
        setSidebarExpanded(expanded) {
            set({ isSidebarExpanded: expanded });
        },
        showAtBottom(component) {
            set({ bottomComponent: component });
        },
        hideComponentShownAtBottom() {
            set({ bottomComponent: null });
        },
    }));

    return (
        <SplitViewStoreContext.Provider value={store}>
            {children}
        </SplitViewStoreContext.Provider>
    );
};

export const useSplitViewStore = <T,>(
    selector: (state: SplitViewStore) => T
) => {
    const store = useContext(SplitViewStoreContext);

    if (!store) {
        throw OutOfProviderError({
            context: "SplitViewStoreContext",
        });
    }

    return useStore(store, selector);
};
