"use client";

import { hasValue } from "@/common/utilities/hasValue";
import { useSplitViewStore } from "@/component-library/components/layout/split-view/application/SplitViewStoreContext";
import {
    splitViewBottomComponentPositionerStyle,
    splitViewBottomComponentWrapperStyle,
} from "@/component-library/components/layout/split-view/SplitViewBottomComponentWrapper.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import React, { ReactNode, useLayoutEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { Transition } from "react-transition-group";

const animationDurationInMs = Magnitudes.durationsInS.s * 1000;

interface SplitViewBottomComponentWrapperProps {}

const SplitViewBottomComponentWrapper: React.FC<
    SplitViewBottomComponentWrapperProps
> = () => {
    const bottomComponent = useSplitViewStore((state) => state.bottomComponent);

    const [currentComponent, setCurrentComponent] = useState<
        ReactNode | undefined
    >(undefined);

    const [animationStyles, animationApi] = useSpring(() => ({
        config: {
            duration: animationDurationInMs,
        },
    }));

    useLayoutEffect(() => {
        if (hasValue(bottomComponent)) {
            setCurrentComponent(bottomComponent);
            void animationApi.start({
                to: {
                    opacity: 1,
                    transform: "translateY(0%)",
                },
                config: {
                    duration: animationDurationInMs,
                },
            });
        } else {
            void animationApi.start({
                to: {
                    opacity: 0,
                    transform: "translateY(100%)",
                },
                config: {
                    duration: animationDurationInMs,
                },
            });

            const timeout = setTimeout(() => {
                setCurrentComponent(bottomComponent);
            }, animationDurationInMs);

            return () => clearTimeout(timeout);
        }
    }, [animationApi, bottomComponent]);

    return (
        <div className={splitViewBottomComponentWrapperStyle}>
            <Transition
                in={hasValue(bottomComponent)}
                timeout={animationDurationInMs}
                unmountOnExit
                mountOnEnter
            >
                <animated.div
                    className={splitViewBottomComponentPositionerStyle}
                    style={animationStyles}
                >
                    {currentComponent}
                </animated.div>
            </Transition>
        </div>
    );
};

export default SplitViewBottomComponentWrapper;
