"use client";

import { NoValue } from "@/common/domain/entities/typing/NoValue";
import { hasValue } from "@/common/utilities/hasValue";
import { WezooSWRConfig } from "@/configs/WezooSWRConfig";
import { getSingleHostLocation } from "@/features/host-locations/application/getSingleHostLocation";
import { THostLocationFullInformationSchema } from "@/features/host-locations/domain/entities/schemas/HostLocationFullInformationSchema";
import { WezooResourceTypeSchema } from "@/features/resource/resource-types/shared/WezooResourceType";
import { useSearchParams } from "next/navigation";
import useSWR from "swr";

export const useSingleHostLocation = (hostLocationId: string | NoValue) => {
    const queryParams = useSearchParams();

    return useSWR<THostLocationFullInformationSchema | null>(
        hostLocationId
            ? [
                  "useSingleHostLocation",
                  hostLocationId,
                  JSON.stringify(Array.from(queryParams.entries())),
                  JSON.stringify(WezooResourceTypeSchema.enum.ROOM),
              ]
            : null,
        () => {
            if (!hasValue(hostLocationId)) {
                return null;
            }

            return getSingleHostLocation({
                hostLocationId,
                queryParams: Object.fromEntries(queryParams.entries()),
            });
        },
        {
            ...WezooSWRConfig.defaultSWRConfig,
        }
    );
};
