"use client";

import { useApplyableParameterStore } from "@/app/(main)/discover/[[...params]]/(application)/useApplyableParameterStore";
import { DiscoverAppBarSearchComponent } from "@/app/(main)/discover/(layout)/(components)/(discover-app-bar)/DiscoverAppBarSearchComponent";
import { useDiscoverMapStore } from "@/app/(main)/discover/[[...params]]/(application)/useDiscoverMapStore";
import { mapQueryStateNamedLocationKey } from "@/app/(main)/discover/[[...params]]/(components)/(map)/mapQueryStateKeys";
import { reportInfo } from "@/common/application/debug";
import { GeoRetrieveResult } from "@/component-library/components/geo-search/useMapboxRetrieveById";
import { ReusableMapImperativeHandle } from "@/component-library/components/map-reusable/ReusableMap";
import { WezooGeoConfig } from "@/configs/discover/WezooMapConfig";
import { capacityFilterQueryParam } from "@/features/filtering/filters/capacity-filter/capacityFilterQueryParam";
import { parseAsTimezonelessDate } from "@/features/filtering/filters/date-time-filter/date-time-filter-parser";
import {
    dateTimeFilterQueryEndKey,
    dateTimeFilterQueryStartKey,
} from "@/features/filtering/filters/date-time-filter/dateTimeFilterQueryKeys";
import { GeographicCoordinate } from "@/features/host-locations/domain/entities/GeographicCoordinate";
import { parseAsInteger, parseAsString, useQueryState } from "nuqs";
import { FC, MutableRefObject, useCallback, useEffect, useState } from "react";

interface DiscoverAppBarSearchComponentWrapperProps {
    mapRef: MutableRefObject<ReusableMapImperativeHandle | null>;
}

export const DiscoverMapAppBarSearchComponentWrapper: FC<
    DiscoverAppBarSearchComponentWrapperProps
> = ({ mapRef }) => {
    const [placeholder, setPlaceholder] = useState<string | undefined>(
        undefined
    );
    const { setMapBounds, setMapState } = useDiscoverMapStore((state) => state);

    const { params } = useApplyableParameterStore();
    const [query] = useState<string>("");
    const [, setStart] = useQueryState(
        dateTimeFilterQueryStartKey,
        parseAsTimezonelessDate
    );
    const [, setEnd] = useQueryState(
        dateTimeFilterQueryEndKey,
        parseAsTimezonelessDate
    );
    const [, setCapacity] = useQueryState(
        capacityFilterQueryParam,
        parseAsInteger
    );
    const [location, setLocation] = useQueryState(
        mapQueryStateNamedLocationKey,
        parseAsString
    );

    const [selectedLocation, setSelectedLocation] =
        useState<GeoRetrieveResult | null>(null);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const onSearch = useCallback(() => {
        void setCapacity(
            params[capacityFilterQueryParam]
                ? parseAsInteger.parse(params[capacityFilterQueryParam])
                : null
        );

        void setStart(
            params[dateTimeFilterQueryStartKey]
                ? parseAsTimezonelessDate.parse(
                      params[dateTimeFilterQueryStartKey]
                  )
                : null
        );

        void setEnd(
            params[dateTimeFilterQueryEndKey]
                ? parseAsTimezonelessDate.parse(
                      params[dateTimeFilterQueryEndKey]
                  )
                : null
        );

        if (selectedLocation) {
            void setLocation(selectedLocation.name);
            setMapState({
                currentPosition: new GeographicCoordinate({
                    latitude: selectedLocation.coordinates[1],
                    longitude: selectedLocation.coordinates[0],
                }),
                zoom: 12,
            });
            if (selectedLocation.bbox) {
                reportInfo("Moving to location with bbox", selectedLocation);
                setMapBounds({
                    minLatitude: selectedLocation.bbox[1],
                    minLongitude: selectedLocation.bbox[0],
                    maxLatitude: selectedLocation.bbox[3],
                    maxLongitude: selectedLocation.bbox[2],
                });
            } else {
                reportInfo("Moving to location", selectedLocation);
                setMapBounds({
                    minLatitude:
                        selectedLocation.coordinates[1] -
                        WezooGeoConfig.bboxFromPointOffset,
                    minLongitude:
                        selectedLocation.coordinates[0] -
                        WezooGeoConfig.bboxFromPointOffset,
                    maxLatitude:
                        selectedLocation.coordinates[1] +
                        WezooGeoConfig.bboxFromPointOffset,
                    maxLongitude:
                        selectedLocation.coordinates[0] +
                        WezooGeoConfig.bboxFromPointOffset,
                });
            }
        }

        // Search does different things in different contexts.
        // If we're on the map (i.e. the map is visible), we want to search move the map to the
        // location. If we're somewhere else, we want to redirect to the map page with the right
        // query params.
        if (mapRef.current && selectedLocation) {
            if (selectedLocation.bbox) {
                void mapRef.current.zoomToBounds({
                    topLeftCorner: new GeographicCoordinate({
                        latitude: selectedLocation.bbox[1],
                        longitude: selectedLocation.bbox[0],
                    }),
                    bottomRightCorner: new GeographicCoordinate({
                        latitude: selectedLocation.bbox[3],
                        longitude: selectedLocation.bbox[2],
                    }),
                    options: {
                        animate: true,
                        padding: 50,
                        duration: 2000,
                    },
                });
            } else {
                void mapRef.current.moveTo(
                    new GeographicCoordinate({
                        latitude: selectedLocation.coordinates[1],
                        longitude: selectedLocation.coordinates[0],
                    }),
                    15
                );
            }
        }
    }, [
        params,
        mapRef,
        query,
        selectedLocation,
        setCapacity,
        setStart,
        setEnd,
    ]);

    useEffect(() => {
        if (location) {
            setPlaceholder(location);
        }
    }, [location]);

    return (
        <DiscoverAppBarSearchComponent
            placeholder={placeholder}
            onSearch={onSearch}
            setSelectedLocation={setSelectedLocation}
            showResourceTypeFilter={true}
        />
    );
};
