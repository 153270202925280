import { mapboxSessionToken } from "@/component-library/components/geo-search/mapboxSessionToken";
import { WezooMapConfig } from "@/configs/discover/WezooMapConfig";
import { useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";

interface RawGeoSearchResult {
    name: string;
    mapbox_id: string;
    feature_type: "place" | "poi" | string;
    address?: string;
    full_address?: string;
    place_formatted?: string;
    context?: {
        country?: {
            name: string;
            country_code: string;
            country_code_alpha_3: string;
        };
        postcode?: {
            id: string;
            name: string;
        };
        place?: {
            id: string;
            name: string;
        };
        neighborhood?: {
            id: string;
            name: string;
        };
        address?: {
            name: string;
            address_number: string;
            street_name: string;
        };
        street?: {
            name: string;
        };
    };
}

export interface GeoSearchResult {
    id: string;
    name: string;
    address?: string;
    type: "place" | "poi" | string;
}

export const useGeoSearch = (
    query: string,
    proximity?: {
        latitude: number;
        longitude: number;
    },
    limit: number = 5
) => {
    const [debouncedQuery] = useDebounceValue(query, 300);
    const [results, setResults] = useState<RawGeoSearchResult[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (!debouncedQuery) {
            setResults([]);
            return;
        }

        const fetchResults = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `https://api.mapbox.com/search/searchbox/v1/suggest?q=${debouncedQuery.slice(
                        0,
                        200
                    )}&limit=${limit}&types=address,region,country,block,street,postcode,district,place,locality,neighborhood,prefecture,city,oaza,chome,poi&language=en&&country=NL,DE,BE,FR,GB,PT,ES&session_token=${mapboxSessionToken}&access_token=${WezooMapConfig.accessToken}${
                        proximity
                            ? `&proximity=${proximity.longitude},${proximity.latitude}`
                            : ""
                    }`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch results");
                }
                const data = (await response.json()) as {
                    suggestions: RawGeoSearchResult[];
                };
                setResults(data.suggestions);
            } catch (e: any) {
                if (typeof e === "string") {
                    setError(new Error(e));
                } else {
                    setError(e);
                }
            } finally {
                setLoading(false);
            }
        };

        void fetchResults();
    }, [debouncedQuery]);

    const formattedResults = results.map((result) => ({
        id: result.mapbox_id,
        name: result.name,
        address: result.full_address || result.address,
        type: result.feature_type,
    }));

    return { result: formattedResults, loading, error };
};
