import { AppBarLinks } from "@/app/(main)/discover/(layout)/(components)/(discover-app-bar)/(links)/AppBarLinks";
import {
    discoverAppBarHomeStyle,
    discoverAppBarImageStyle,
    discoverAppBarTopContentStyle,
} from "@/app/(main)/discover/(layout)/(components)/(discover-app-bar)/DiscoverAppBar.css";
import WezooLogo from "@/assets/wezoo-logo-blue.svg";
import { clsx } from "clsx";
import { FC } from "react";

export const DiscoverAppBarMenuComponent: FC = () => {
    return (
        <div className={clsx(discoverAppBarTopContentStyle)}>
            <div className={clsx(discoverAppBarHomeStyle)}>
                <a
                    href={"https://wezoo.com"}
                    style={{ padding: 0, margin: 0, height: 19 }}
                >
                    <WezooLogo
                        title={"Wezoo logo"}
                        className={clsx(discoverAppBarImageStyle)}
                    />
                </a>
            </div>
            <AppBarLinks />
        </div>
    );
};
