import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { DropdownElement } from "@/component-library/components/user-input/search-field/DropdownElement";
import {
    searchFieldDropdownStyle,
    searchFieldInputStyle,
    searchFieldSelectedInputStyle,
    searchFieldSelectedStyle,
    searchFieldStyle,
} from "@/component-library/components/user-input/search-field/SearchFieldStyles.css";
import { TextField } from "@/component-library/components/user-input/text-field/TextField";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { stylex } from "@/component-library/utilities/stylex";
import {
    autoUpdate,
    offset,
    shift,
    size,
    useClick,
    useFloating,
    useFocus,
    useInteractions,
    useRole,
} from "@floating-ui/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { clsx } from "clsx";
import React, {
    ComponentProps,
    CSSProperties,
    forwardRef,
    ReactNode,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import { useOnClickOutside } from "usehooks-ts";

interface SearchFieldElementProps {
    id: string;
    label: string;
    icon?: ReactNode;
    subLabel?: string;
}

interface SearchFieldProps
    extends Omit<ComponentProps<typeof TextField>, "prefixIcon"> {
    descriptionForScreenReaders: string;
    dropdownItems?: SearchFieldElementProps[];
    onSelection?: (result?: SearchFieldElementProps) => void;
    onSubmitItem?: (result?: SearchFieldElementProps) => void;
    wrapperStyle?: React.CSSProperties;
    dropdownStyle?: React.CSSProperties;
    dropdownItemStyle?: React.CSSProperties;
    prefixIcon?: ReactNode;
}

const SearchField = forwardRef<
    {
        element: HTMLInputElement;
        closeDropdown: () => void;
    },
    SearchFieldProps
>(
    (
        {
            className,
            containerClassName,
            descriptionForScreenReaders,
            dropdownItems,
            onSelection,
            onSubmitItem,
            wrapperStyle,
            dropdownStyle,
            dropdownItemStyle,
            prefixIcon,
            ...restProps
        },
        refIn
    ) => {
        const [dropdownOpen, setDropdownOpen] = useState(false);
        const [activeIndex, setActiveIndex] = useState<number | null>(null);
        const [selectedId, setSelectedId] = React.useState<string | null>(null);

        const wrapperRef = useRef<HTMLDivElement | null>(null);
        const inputRef = useRef<HTMLInputElement | null>(null);

        const onSelectionEvent = useCallback(
            (r?: SearchFieldElementProps) => {
                if (!r) {
                    setSelectedId(null);
                } else {
                    setSelectedId(r.id);
                }

                if (onSelection) {
                    onSelection(r);
                }
                setDropdownOpen(false);
            },
            [onSelection]
        );

        const onSubmitEvent = useCallback(
            (r?: SearchFieldElementProps) => {
                if (onSubmitItem) {
                    onSubmitItem(r);
                }
                setDropdownOpen(false);
            },
            [onSubmitItem]
        );

        useEffect(() => {
            if (dropdownItems && dropdownItems.length > 0) {
                setActiveIndex(0);
            }
        }, [dropdownItems]);

        useImperativeHandle(
            refIn,
            () => {
                return {
                    element: inputRef.current as HTMLInputElement,
                    closeDropdown: () => {
                        setDropdownOpen(false);
                    },
                };
            },
            [inputRef]
        );

        const handleKeyDown = (
            event: React.KeyboardEvent<HTMLInputElement>
        ) => {
            if (!dropdownItems || dropdownItems.length === 0) {
                return;
            }

            switch (event.key) {
                case "ArrowDown":
                    setActiveIndex((prevIndex) =>
                        prevIndex === null ||
                        prevIndex === dropdownItems.length - 1
                            ? 0
                            : prevIndex + 1
                    );
                    break;
                case "ArrowUp":
                    setActiveIndex((prevIndex) =>
                        prevIndex === null || prevIndex === 0
                            ? dropdownItems.length - 1
                            : prevIndex - 1
                    );
                    break;
                case "Enter":
                    if (activeIndex !== null) {
                        const item = dropdownItems[activeIndex];
                        onSelectionEvent(item);

                        setActiveIndex(null); // Close dropdown after selection
                    } else if (onSubmitItem) {
                        const item = dropdownItems.find(
                            (i) => i.id === selectedId
                        );
                        onSubmitEvent(item);
                    }
                    break;
                case "Escape":
                    setActiveIndex(null);
                    setDropdownOpen(false);
                    break;
                default:
                    setSelectedId(null);
                    break;
            }
        };

        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.addEventListener(
                    "keydown",
                    handleKeyDown as any
                );
            }
            const ref = inputRef.current;
            return () => {
                if (ref) {
                    ref.removeEventListener("keydown", handleKeyDown as any);
                }
            };
        }, [dropdownItems, activeIndex]);

        //const [debouncedDropdownOpen] = useDebounceValue(dropdownOpen, 200);
        const { refs, floatingStyles, context } = useFloating({
            strategy: "absolute",
            placement: "bottom",
            open: dropdownOpen,
            onOpenChange: setDropdownOpen,
            middleware: [
                offset(Magnitudes.spacingInRem.xxs),
                shift(),
                size({
                    apply({ rects, elements }) {
                        Object.assign(elements.floating.style, {
                            minWidth: `${rects.reference.width}px`,
                        });
                    },
                }),
            ],
            whileElementsMounted: autoUpdate,
        });

        const click = useClick(context);
        const focus = useFocus(context);

        const role = useRole(context, {
            role: "select",
        });

        const { getFloatingProps } = useInteractions([click, focus, role]);
        const floatingProps = useMemo(
            () => getFloatingProps(),
            [getFloatingProps]
        );

        useEffect(() => {
            refs.setReference(wrapperRef.current);
        }, [refs, wrapperRef]);

        useOnClickOutside(wrapperRef, () => {
            setActiveIndex(null);
            setDropdownOpen(false);
        });

        return (
            <div ref={wrapperRef} style={wrapperStyle}>
                <VisuallyHidden>{descriptionForScreenReaders}</VisuallyHidden>
                <TextField
                    ref={inputRef}
                    containerClassName={`${searchFieldStyle} ${
                        selectedId ? searchFieldSelectedStyle : ""
                    } ${containerClassName ?? ""}`}
                    className={`${searchFieldInputStyle} ${
                        selectedId ? searchFieldSelectedInputStyle : ""
                    } ${className ?? ""}`}
                    prefixIcon={
                        prefixIcon || <FontAwesomeIcon icon={faSearch} />
                    }
                    onFocus={() => {
                        setDropdownOpen(true);
                    }}
                    {...restProps}
                />
                {dropdownOpen && (
                    <div
                        ref={refs.setFloating}
                        {...floatingProps}
                        className={clsx(
                            searchFieldDropdownStyle,
                            floatingProps["className"] as string
                        )}
                        style={stylex(
                            dropdownStyle,
                            floatingStyles,
                            floatingProps["style"] as CSSProperties
                        )}
                        onMouseLeave={() => setActiveIndex(null)}
                    >
                        {dropdownItems?.map((item, index) => (
                            <DropdownElement
                                key={index}
                                onClick={() => onSelectionEvent(item)}
                                onHover={() => setActiveIndex(index)}
                                active={activeIndex === index}
                                dropdownItemStyle={dropdownItemStyle}
                                {...item}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    }
);
SearchField.displayName = "SearchField";

export default SearchField;
