"use client";

import ambiguousMapThumbnail from "@/assets/graphics/ambiguous-map-thumbnail.png";
import Button from "@/component-library/components/buttons/button/Button";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import {
    locationCollectionMapLinkCardCTAWrapperStyle,
    locationCollectionMapLinkCardImageStyle,
    locationCollectionMapLinkCardStyle,
} from "@/slices/LocationCollection/LocationCollectionMapLinkCard.css";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import Image from "next/image";
import React from "react";

interface LocationCollectionMapLinkCardProps {
    onClick?: () => void;
}

const MapLinkCard: React.FC<LocationCollectionMapLinkCardProps> = ({
    onClick,
}) => {
    return (
        <div
            className={locationCollectionMapLinkCardStyle}
            style={assignInlineVars({
                padding: `${Magnitudes.spacingInRem.m}rem`,
            })}
        >
            <Image
                src={ambiguousMapThumbnail}
                alt="Thumbnail of a map"
                onClick={onClick}
                className={locationCollectionMapLinkCardImageStyle}
                style={assignInlineVars({
                    borderRadius: `${Magnitudes.radiiInPx.m}px`,
                    cursor: "pointer",
                })}
            />
            <div className={locationCollectionMapLinkCardCTAWrapperStyle}>
                <Button onClick={onClick}>
                    <FontAwesomeIcon icon={faMap} />
                    Open map
                </Button>
            </div>
        </div>
    );
};

export default MapLinkCard;
