"use client";

import {
    textFieldContainerStyle,
    textFieldInputStyle,
} from "@/component-library/components/user-input/text-field/TextFieldStyles.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React, { HTMLAttributes, useImperativeHandle, useRef } from "react";

interface TextFieldProps
    extends Omit<HTMLAttributes<HTMLInputElement>, "onChange"> {
    name: string;
    value?: string;
    onChange?: (newValue: string) => void;
    required?: boolean;
    placeholder?: string;

    prefixIcon?: React.ReactNode;
    postfixIcon?: React.ReactNode;
    containerClassName?: string;

    removeTopMargin?: boolean;
}

/**
 * A styled field for entering text on a single line.
 * @param prefixIcon - Icon to be displayed before the input.
 * @param postfixIcon - Icon to be displayed after the input.
 */
export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
    (
        {
            prefixIcon,
            postfixIcon,
            containerClassName,
            className,
            onChange,
            removeTopMargin = false,
            ...restProps
        },
        refIn
    ) => {
        const inputRef = useRef<HTMLInputElement | null>(null);

        useImperativeHandle(refIn, () => inputRef.current!, [inputRef]);

        return (
            <div
                className={clsx(textFieldContainerStyle, containerClassName)}
                onClick={() => inputRef.current?.focus()}
                style={assignInlineVars({
                    marginTop: removeTopMargin
                        ? undefined
                        : `${Magnitudes.spacingInRem.xxs}rem`,
                })}
            >
                {prefixIcon}
                <input
                    ref={inputRef}
                    className={`${textFieldInputStyle} ${className ?? ""}`}
                    onChange={(event) => {
                        onChange?.(event.target.value);
                    }}
                    {...restProps}
                />
                {postfixIcon}
            </div>
        );
    }
);
TextField.displayName = "TextField";
