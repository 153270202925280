"use client";

import DiscoverMapList from "src/app/(main)/discover/[[...params]]/(components)/(map)/DiscoverMapList";
import DiscoverMap from "@/app/(main)/discover/[[...params]]/(components)/(map)/DiscoverMap";
import { DiscoverMapAppBar } from "@/app/(main)/discover/[[...params]]/(components)/(map)/DiscoverMapAppBar";
import {
    mapQueryStateLatitudeKey,
    mapQueryStateLongitudeKey,
    mapQueryStateZoomKey,
} from "@/app/(main)/discover/[[...params]]/(components)/(map)/mapQueryStateKeys";
import { SecondaryFilterList } from "@/app/(main)/discover/[[...params]]/(components)/(secondary-filters)/SecondaryFilterList";
import { discoverMapListSplitViewMobileMaxWidthInPx } from "@/app/(main)/discover/[[...params]]/(components)/discover-map-constants";
import { discoverMapSplitViewStyle } from "@/app/(main)/discover/[[...params]]/(components)/DiscoverMapSplitViewStyle.css";
import Hidden from "@/component-library/components/layout/hidden/Hidden";
import { SplitViewStoreProvider } from "@/component-library/components/layout/split-view/application/SplitViewStoreContext";
import SplitViewBottomComponentWrapper from "@/component-library/components/layout/split-view/SplitViewBottomComponentWrapper";
import {
    AMSTERDAM_LONG_LAT,
    ReusableMapStoreProvider,
} from "@/component-library/components/map-reusable/createReusableMapStore";
import { ReusableMapImperativeHandle } from "@/component-library/components/map-reusable/ReusableMap";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { WezooMapConfig } from "@/configs/discover/WezooMapConfig";
import { GeographicCoordinate } from "@/features/host-locations/domain/entities/GeographicCoordinate";
import { parseAsFloat, useQueryState } from "nuqs";
import React, { useMemo, useRef } from "react";
import { useMediaQuery } from "usehooks-ts";

interface DiscoverMapListSplitViewProps {}

const mapOptions = {
    mapConfig: WezooMapConfig,
    isInteractive: true,

    useOnlyZoomButtons: false,
    minZoom: 6,
    maxZoom: 18,
};

const DiscoverMapSplitView: React.FC<DiscoverMapListSplitViewProps> = () => {
    const mapRef = useRef<ReusableMapImperativeHandle | null>(null);
    const [longitude] = useQueryState(mapQueryStateLongitudeKey, parseAsFloat);
    const [latitude] = useQueryState(mapQueryStateLatitudeKey, parseAsFloat);
    const [zoom] = useQueryState(mapQueryStateZoomKey, parseAsFloat);

    const initialView = useMemo(() => {
        return {
            initialLocation: new GeographicCoordinate({
                latitude: latitude ?? AMSTERDAM_LONG_LAT.latitude,
                longitude: longitude ?? AMSTERDAM_LONG_LAT.longitude,
            }),
            initialZoom: zoom ?? 7,
        };
        // Initial view is only relevant on first load; it does not need to be updated
        // afterward.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isMobile = useMediaQuery(
        `screen and (max-width: ${discoverMapListSplitViewMobileMaxWidthInPx}px)`,
        { initializeWithValue: true }
    );

    return (
        <ReusableMapStoreProvider
            key="reusable-map-store-provider"
            mapOptions={mapOptions}
            initialView={initialView}
        >
            <DiscoverMapAppBar mapRef={mapRef} />
            <Flex
                direction={"row"}
                gap={0}
                className={discoverMapSplitViewStyle}
            >
                <SplitViewStoreProvider>
                    {!isMobile && <SecondaryFilterList />}
                    {!isMobile && <DiscoverMapList />}
                    <DiscoverMap ref={mapRef} />

                    <Hidden isShowing={isMobile}>
                        <SplitViewBottomComponentWrapper />
                    </Hidden>
                </SplitViewStoreProvider>
            </Flex>
        </ReusableMapStoreProvider>
    );
};

export default DiscoverMapSplitView;
