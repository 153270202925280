import React, { ReactNode } from "react";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { theme } from "@/component-library/themes/theme.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";

interface DropdownElementProps {
    id: string;
    active?: boolean;
    label: string;
    icon?: ReactNode;
    subLabel?: string;
    onClick: () => void;
    onHover?: () => void;
    dropdownItemStyle?: React.CSSProperties;
}

export const DropdownElement: React.FC<DropdownElementProps> = ({
    onClick,
    onHover,
    active,
    label,
    icon,
    subLabel,
}) => {
    return (
        <Flex
            direction={"row"}
            style={assignInlineVars({
                cursor: "pointer",
                backgroundColor: active
                    ? theme.colors.surface.darkSurface.o80
                    : "transparent", // Change
                borderRadius: `${Magnitudes.radiiInPx.s}px`,
                transition: "background-color 0.05s, padding 0.2s",
                paddingLeft: active
                    ? `${Magnitudes.spacingInRem.s}rem`
                    : `${Magnitudes.spacingInRem.xxs}rem`,
                width: "100%",
            })}
            onClick={onClick}
            onMouseEnter={onHover}
            gap={0}
        >
            <div
                style={assignInlineVars({
                    width: "20px",
                    display: "flex",
                    justifyContent: "center",
                    color: theme.colors.basic.primary,
                })}
            >
                {icon}
            </div>
            <Flex
                direction={"column"}
                alignItems={"stretch"}
                justifyContent={"center"}
                style={assignInlineVars({
                    padding: `${Magnitudes.spacingInRem.xxxs}rem ${Magnitudes.spacingInRem.m}rem`,
                    width: "100%",
                    overflow: "hidden",
                    height: "50px",
                })}
                gap={0}
            >
                <div
                    style={assignInlineVars({
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        color: theme.colors.basic.primary,
                    })}
                >
                    {label}
                </div>
                <div
                    style={assignInlineVars({
                        fontSize: "1.1rem",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        color: theme.colors.text.secondary,
                    })}
                >
                    {subLabel}
                </div>
            </Flex>
        </Flex>
    );
};
