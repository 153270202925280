import { mapQueryStateViewKey } from "@/app/(main)/discover/[[...params]]/(components)/(map)/mapQueryStateKeys";
import Button from "@/component-library/components/buttons/button/Button";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { theme } from "@/component-library/themes/theme.css";
import { faList, faMap } from "@fortawesome/free-solid-svg-icons";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { parseAsStringLiteral, useQueryState } from "nuqs";
import { FC, useEffect } from "react";

export const discoverViewParser = parseAsStringLiteral(["list", "map"])
    .withDefault("map")
    .withOptions({
        history: "push",
    });

export const DiscoverViewSwitch: FC = () => {
    const [view, setView] = useQueryState(
        mapQueryStateViewKey,
        discoverViewParser
    );
    const [rawView] = useQueryState(mapQueryStateViewKey);

    useEffect(() => {
        if (rawView === null) {
            void setView("map");
        }
    }, [setView, rawView]);

    const buttonGroup = (
        <Flex
            direction={"row"}
            gap={0}
            style={assignInlineVars({
                backgroundColor: theme.colors.surface.lightSurface.o100,
                borderRadius: `${Magnitudes.radiiInPx.m}px`,
                border: theme.styles.surfaceOutline.medium,
            })}
        >
            <Button
                variant={"secondary"}
                style={assignInlineVars({
                    margin: `-1px`,
                    backgroundColor:
                        view === "list"
                            ? theme.colors.semantic.interactive.default
                            : `rgba(0, 0, 0, 0)`,
                    color:
                        view === "list"
                            ? theme.colors.basic.white
                            : theme.colors.text.primary,
                    border:
                        view === "list"
                            ? theme.styles.surfaceOutline.medium
                            : `rgba(0, 0, 0, 0)`,
                })}
                onClick={() => {
                    if (view === "list") {
                        return;
                    }
                    void setView("list");
                }}
            >
                <FontAwesomeIcon icon={faList} />
                List
            </Button>
            <Button
                variant={"secondary"}
                style={assignInlineVars({
                    margin: `-1px`,
                    backgroundColor:
                        view === "map"
                            ? theme.colors.semantic.interactive.default
                            : `rgba(0, 0, 0, 0)`,
                    color:
                        view === "map"
                            ? theme.colors.basic.white
                            : theme.colors.text.primary,
                    border:
                        view === "map"
                            ? theme.styles.surfaceOutline.medium
                            : `rgba(0, 0, 0, 0)`,
                })}
                onClick={() => {
                    if (view === "map") {
                        return;
                    }
                    void setView("map");
                }}
            >
                <FontAwesomeIcon icon={faMap} />
                Map
            </Button>
        </Flex>
    );

    return buttonGroup;
};
