"use client";

import { mapQueryStateViewKey } from "@/app/(main)/discover/[[...params]]/(components)/(map)/mapQueryStateKeys";
import DiscoverListView from "@/app/(main)/discover/[[...params]]/(components)/DiscoverListView";
import { DiscoverMapProvider } from "@/app/(main)/discover/[[...params]]/(components)/DiscoverMapProvider";
import DiscoverMapSplitView from "@/app/(main)/discover/[[...params]]/(components)/DiscoverMapSplitView";
import { discoverViewParser } from "src/app/(main)/discover/[[...params]]/(components)/DiscoverViewSwitch";
import { discoverPageStyle } from "@/app/(main)/discover/[[...params]]/page.css";
import { useQueryState } from "nuqs";
import React from "react";

/**
 * The route optional param segments are passed into the
 * [optionalParamSegments] field, which have to be parsed into the actual
 * params. This is done with [useCatchAllParams].
 * @param optionalParamSegments contains a list of the
 *   optional params.
 */
interface DiscoverRouteProps {}

/**
 * The main route for searching for resources.
 * @constructor
 */
const DiscoverRoute: React.FunctionComponent<DiscoverRouteProps> = () => {
    const [view] = useQueryState(mapQueryStateViewKey, discoverViewParser);

    if (view === "list") {
        return (
            <DiscoverMapProvider>
                <div className={discoverPageStyle}>
                    <DiscoverListView />
                </div>
            </DiscoverMapProvider>
        );
    }

    return (
        <DiscoverMapProvider>
            <div className={discoverPageStyle}>
                <DiscoverMapSplitView />
            </div>
        </DiscoverMapProvider>
    );
};

export default DiscoverRoute;
