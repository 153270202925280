"use client";

import { geoSearchInputInnerInput } from "@/component-library/components/geo-search/GeoSearchInput.css";
import {
    GeoRetrieveResult,
    useMapboxRetrieveById,
} from "@/component-library/components/geo-search/useMapboxRetrieveById";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useGeoSearch } from "@/component-library/components/geo-search/useGeoSearch";
import SearchField from "@/component-library/components/user-input/search-field/SearchField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCity,
    faTreeCity,
    faMapMarker,
    faFlag,
} from "@fortawesome/free-solid-svg-icons";
import { assignInlineVars } from "@vanilla-extract/dynamic";

interface GeoSearchInputProps {
    query: string;
    setQuery: (value: string) => void;
    placeholder?: string;
    selectedItemId?: string;
    proximity?: {
        latitude: number;
        longitude: number;
    };
    onSubmitItem?: (result?: GeoRetrieveResult) => void;
    onSelection?: (result?: GeoRetrieveResult) => void;
    limit?: number;
}

const GeoSearchInput = forwardRef<
    {
        closeDropdown: () => void;
    },
    GeoSearchInputProps
>(
    (
        {
            query,
            setQuery,
            placeholder,
            proximity,
            onSubmitItem,
            onSelection,
            selectedItemId,
            limit,
        },
        refIn
    ) => {
        const [internalPlaceholder, setInternalPlaceholder] =
            React.useState<string>();
        const inputRef = React.useRef<{
            element: HTMLInputElement;
            closeDropdown: () => void;
        } | null>(null);
        const [selected, setSelected] = React.useState<string | null>(null);
        const { result } = useGeoSearch(query, proximity, limit);
        const { result: selectedItemResult } = useMapboxRetrieveById(
            selected || undefined
        );

        useEffect(() => {
            if (selectedItemId) {
                setSelected(selectedItemId);
            }
        }, [selectedItemId]);

        useEffect(() => {
            setInternalPlaceholder(placeholder);
        }, [placeholder]);

        useEffect(() => {
            setInternalPlaceholder(undefined);
        }, [query]);

        const mapTypeToIcon = (type: string) => {
            switch (type) {
                case "country":
                    return faFlag;
                case "locality":
                    return faTreeCity;
                case "place":
                    return faCity;
                default:
                    return faMapMarker;
            }
        };

        useEffect(() => {
            onSelection?.(selectedItemResult || undefined);
        }, [onSelection, selectedItemResult]);

        useImperativeHandle(
            refIn,
            () => ({
                closeDropdown: () => {
                    inputRef.current?.closeDropdown();
                },
            }),
            [inputRef]
        );

        return (
            <SearchField
                ref={(r) => {
                    inputRef.current = r;
                }}
                descriptionForScreenReaders={"Search for a geographic location"}
                name={"search"}
                prefixIcon={<FontAwesomeIcon icon={faMapMarker} />}
                placeholder={internalPlaceholder || "Where?"}
                style={assignInlineVars({
                    fontSize: `${Magnitudes.fontInRem.m}rem`,
                    width: "100%",
                    padding: "0",
                })}
                wrapperStyle={assignInlineVars({
                    width: "100%",
                    padding: "0",
                })}
                containerClassName={geoSearchInputInnerInput}
                value={query}
                onChange={(q) => {
                    setQuery(q);
                    setSelected(null);
                }}
                onSelection={(r) => {
                    if (r) {
                        setQuery(r.label);
                        if (onSelection) {
                            setSelected(r.id);
                        }
                    }
                }}
                dropdownItems={result.map((r) => ({
                    id: r.id,
                    label: r.name,
                    subLabel: r.address,
                    icon: <FontAwesomeIcon icon={mapTypeToIcon(r.type)} />,
                }))}
                onSubmitItem={() => {
                    onSubmitItem?.(selectedItemResult || undefined);
                }}
            />
        );
    }
);

GeoSearchInput.displayName = "GeoSearchInput";

export default GeoSearchInput;
