"use client";

import DiscoverList from "@/app/(main)/discover/[[...params]]/(components)/(list)/DiscoverList";
import { DiscoverMapAppBar } from "@/app/(main)/discover/[[...params]]/(components)/(map)/DiscoverMapAppBar";
import { SecondaryFilterList } from "@/app/(main)/discover/[[...params]]/(components)/(secondary-filters)/SecondaryFilterList";
import { discoverMapListSplitViewMobileMaxWidthInPx } from "@/app/(main)/discover/[[...params]]/(components)/discover-map-constants";
import {
    discoverListViewContentStyle,
    discoverListViewStyle,
} from "@/app/(main)/discover/[[...params]]/(components)/DiscoverListView.css";
import { SplitViewStoreProvider } from "@/component-library/components/layout/split-view/application/SplitViewStoreContext";
import { ReusableMapImperativeHandle } from "@/component-library/components/map-reusable/ReusableMap";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import React, { useRef } from "react";
import { useMediaQuery } from "usehooks-ts";

interface DiscoverListViewProps {}

const DiscoverListView: React.FC<DiscoverListViewProps> = () => {
    const mapRef = useRef<ReusableMapImperativeHandle | null>(null);

    const isMobile = useMediaQuery(
        `screen and (max-width: ${discoverMapListSplitViewMobileMaxWidthInPx}px)`,
        { initializeWithValue: true }
    );

    return (
        <>
            <DiscoverMapAppBar mapRef={mapRef} />
            <div className={discoverListViewStyle}>
                <Flex
                    direction={"row"}
                    className={discoverListViewContentStyle}
                >
                    <SplitViewStoreProvider>
                        {!isMobile && (
                            <SecondaryFilterList showMapLink={true} />
                        )}
                        <DiscoverList />
                    </SplitViewStoreProvider>
                </Flex>
            </div>
        </>
    );
};

export default DiscoverListView;
