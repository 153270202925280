import Flex from "@/component-library/components/organising-content/flex/Flex";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { facilityFilterSideBarComponentStyle } from "@/features/filtering/filters/facility-filter/FacilityFilterSideBarComponentStyle.css";
import FacilityToggleableFilter from "@/features/filtering/filters/facility-filter/FacilityToggleableFilter";
import { useFacilitiesFilter } from "@/features/filtering/filters/facility-filter/useFacilitiesFilter";
import { TFacilitySchema } from "@/features/resource/domain/entities/FacilitySchema";
import React, { Fragment } from "react";

interface FacilityFilterAppBarPopoverProps {}

const FacilityFilterSideBarComponent: React.FC<
    FacilityFilterAppBarPopoverProps
> = () => {
    const { setRequiredFacilities, requiredFacilities, facilitiesInGroups } =
        useFacilitiesFilter();

    return (
        <Flex
            className={facilityFilterSideBarComponentStyle}
            direction={"column"}
            alignItems={"stretch"}
            gap={Magnitudes.spacingInRem.l}
        >
            {facilitiesInGroups &&
                facilitiesInGroups.map((group) => {
                    if (group.facilities.length === 0) {
                        return <Fragment key={group.group.slug} />;
                    }

                    return (
                        <div key={group.group.slug}>
                            <h2>{group.group.display_name}</h2>
                            {group.facilities.map((facility) => {
                                const requiredFacilitiesOrEmpty =
                                    requiredFacilities ?? [];
                                return (
                                    <FacilityToggleableFilter
                                        key={facility.slug}
                                        facility={facility}
                                        isToggled={requiredFacilitiesOrEmpty.some(
                                            (requiredFacility) =>
                                                requiredFacility.slug ===
                                                facility.slug
                                        )}
                                        onToggle={(
                                            toggledFacility,
                                            isRequired
                                        ) => {
                                            let newRequiredFacilities: TFacilitySchema[] =
                                                [];

                                            if (isRequired) {
                                                /**
                                                 * When the filter is
                                                 * toggled on, we push
                                                 * it to the
                                                 * [newRequiredFacilities]
                                                 * which is used to
                                                 * update the store and
                                                 * the query params.
                                                 */
                                                newRequiredFacilities.push(
                                                    ...[
                                                        ...requiredFacilitiesOrEmpty,
                                                        toggledFacility,
                                                    ]
                                                );
                                            } else {
                                                /**
                                                 * If the user unchecked
                                                 * the
                                                 * filter, we remove any
                                                 * matching slugs from
                                                 * required facilities.
                                                 */
                                                newRequiredFacilities =
                                                    requiredFacilitiesOrEmpty.filter(
                                                        (requiredFacility) =>
                                                            requiredFacility.slug !==
                                                            toggledFacility.slug
                                                    );
                                            }

                                            // Update query params to
                                            // reflect new state
                                            void setRequiredFacilities(
                                                newRequiredFacilities.length > 0
                                                    ? newRequiredFacilities
                                                    : null
                                            );
                                        }}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
        </Flex>
    );
};

export default FacilityFilterSideBarComponent;
