import { mapQueryStateSortKey } from "@/app/(main)/discover/[[...params]]/(components)/(map)/mapQueryStateKeys";
import Button from "@/component-library/components/buttons/button/Button";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { sortingSelectDropdownStyle } from "@/component-library/components/sorting-select/SortingSelect.css";
import { DropdownElement } from "@/component-library/components/user-input/search-field/DropdownElement";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { stylex } from "@/component-library/utilities/stylex";
import {
    sortOptions,
    sortParser,
    SortType,
} from "@/features/filtering/application/sortingConstants";
import {
    autoUpdate,
    size,
    useClick,
    useFloating,
    useFocus,
    useInteractions,
    useRole,
} from "@floating-ui/react";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";

import { useQueryState } from "nuqs";
import React, {
    CSSProperties,
    FC,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useOnClickOutside } from "usehooks-ts";

export const SortingSelect: FC = () => {
    const [sortBy, setSortBy] = useQueryState(mapQueryStateSortKey, sortParser);

    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
        strategy: "absolute",
        placement: "bottom",
        open: dropdownOpen,
        onOpenChange: setDropdownOpen,
        middleware: [
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        minWidth: `${rects.reference.width}px`,
                    });
                },
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const focus = useFocus(context);

    const role = useRole(context, {
        role: "select",
    });

    const { getFloatingProps } = useInteractions([click, focus, role]);
    const floatingProps = useMemo(() => getFloatingProps(), [getFloatingProps]);

    useEffect(() => {
        refs.setReference(wrapperRef.current);
    }, [refs, wrapperRef]);

    useOnClickOutside(wrapperRef, () => {
        setActiveIndex(null);
        setDropdownOpen(false);
    });

    const defaultSortItem = {
        type: "",
        label: "Our recommendation",
        icon: faSort,
    };
    const dropdownItems = useMemo(() => {
        return [defaultSortItem, ...sortOptions].map((option) => {
            return {
                id: option.type,
                label: option.label,
                icon: <FontAwesomeIcon icon={option.icon} />,
            };
        });
    }, []);

    const onSelectionEvent = (item: { id: string }) => {
        void setSortBy(item.id as SortType);
        setDropdownOpen(false);
    };
    const selectedItem =
        dropdownItems.find((option) => option.id === sortBy) ||
        dropdownItems[0];

    return (
        <div ref={wrapperRef}>
            <Button
                style={assignInlineVars({
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                })}
                variant={"light"}
                onClick={() => {
                    setDropdownOpen(true);
                }}
            >
                {selectedItem.icon}
                <span
                    style={assignInlineVars({
                        marginLeft: `${Magnitudes.spacingInRem.s}rem`,
                    })}
                >
                    {selectedItem.label}
                </span>
            </Button>
            {dropdownOpen && (
                <div
                    ref={refs.setFloating}
                    {...floatingProps}
                    className={clsx(
                        sortingSelectDropdownStyle,
                        floatingProps["className"] as string
                    )}
                    style={stylex(
                        //dropdownStyle,
                        floatingStyles,
                        floatingProps["style"] as CSSProperties
                    )}
                    onMouseLeave={() => setActiveIndex(null)}
                >
                    {dropdownItems.map((item, index) => (
                        <DropdownElement
                            key={index}
                            onClick={() => onSelectionEvent(item)}
                            onHover={() => setActiveIndex(index)}
                            active={activeIndex === index}
                            //dropdownItemStyle={dropdownItemStyle}
                            {...item}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
// <Select
//     name={"Sorting"}
//     postfixIcon={<FontAwesomeIcon icon={faCaretDown} />}
//     className={sortingSelectCss}
//     onChange={(value) => {
//         const sortBy = value.currentTarget.value;
//         const sortByValue = (
//             sortBy === "" ? null : sortBy
//         ) as SortType | null;
//         void setSortBy(sortByValue);
//     }}
// >
//     <optgroup>
//         <option value={""}>
//             <FontAwesomeIcon icon={faSort} />
//             Default sorting
//         </option>
//         {sortOptions.map((option) => {
//             return (
//                 <option key={option.type} value={option.type}>
//                     {option.label}
//                 </option>
//             );
//         })}
//     </optgroup>
// </Select>
