import { constructRoute } from "@/common/application/routing/constructRoute";
import { PageApiWithShareableUrl } from "@/common/domain/entities/routing/PageApi";
import { ClientSideAccessiblePublicEnvValues } from "@/common/domain/constants/env/ClientSideAccessiblePublicEnvValues";

interface HostLocationPageRouteProps {
    hostLocationId: string;
    params?: Record<string, string>;
}

export const HostLocationPageApi: PageApiWithShareableUrl<
    HostLocationPageRouteProps,
    HostLocationPageRouteProps
> = {
    getRoute: ({ hostLocationId, params }: HostLocationPageRouteProps) => {
        return constructRoute(["discover", "host", hostLocationId], params);
    },

    getShareableUrl: ({ hostLocationId }: HostLocationPageRouteProps) => {
        return `${ClientSideAccessiblePublicEnvValues.wezooWebAppUrl}${HostLocationPageApi.getRoute({ hostLocationId })}`;
    },
};
