import { DiscountsSchema } from "@/features/host-locations/domain/entities/DiscountsSchema";
import { OpeningHourSchema } from "@/features/host-locations/domain/entities/OpeningHourSchema";
import { HostLocationGeneralInformationSchema } from "@/features/host-locations/domain/entities/schemas/HostLocationGeneralInformationSchema";
import { z } from "zod";

export const HostLocationFullInformationSchema =
    HostLocationGeneralInformationSchema.and(
        z.object({
            location: z.object({
                house_rules: z.string().nullable(),
                email: z.string().nullable(),
                phone: z.string().nullable(),
                directions: z.string().nullable(),
                content: z.string().nullable(),
                opening_hours: OpeningHourSchema.array(),
                discounts: DiscountsSchema.array(),
                reviews: z
                    .object({
                        title: z.string(),
                        description: z.string(),
                    })
                    .array(),
            }),
        })
    );
export type THostLocationFullInformationSchema = z.infer<
    typeof HostLocationFullInformationSchema
>;
