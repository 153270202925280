import { WezooError } from "@/common/domain/errors/_base/WezooError";
import { hasValue } from "@/common/utilities/hasValue";
import { Filter } from "@/features/filtering/domain/entities/Filter";
import { TLocationLevelFilterMetadata } from "@/features/host-locations/domain/entities/schemas/HostLocationGeneralInformationSchema";
import { TMinimalFilterableHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/MinimalFilterableHostLocationWithWorkspaceGroupsSchema";

export async function locallyFilterLocations<
    T extends TMinimalFilterableHostLocationWithWorkspaceGroupsSchema,
>(
    _locations: T[],
    filters: Filter<any, any, keyof TLocationLevelFilterMetadata>[],
    queryParams: Record<string, string>
): Promise<{
    filteredLocations: T[];
    error: WezooError | null;
}> {
    const locations = structuredClone(_locations);

    for (const location of locations) {
        await Promise.all(
            location.workspace_groups.map(async (workspaceGroup) => {
                if (!hasValue(workspaceGroup.filterMetadata)) {
                    workspaceGroup.filterMetadata = {};
                }
                await Promise.all(
                    filters.map(async (filter) => {
                        const result =
                            await filter.filterLocally?.filterWorkspaceGroup?.({
                                queryParams,
                                location,
                                workspaceGroup,
                            });

                        workspaceGroup.isMatch =
                            workspaceGroup.isMatch && (result?.isMatch ?? true);
                        workspaceGroup.filterMetadata = {
                            ...workspaceGroup.filterMetadata,
                            [filter.id]: result?.filterMetadata,
                        };
                    })
                );
            })
        );

        filters.forEach((filter) => {
            if (!hasValue(location.filterMetadata)) {
                location.filterMetadata = {};
            }

            const result = filter.filterLocally?.filterLocation?.({
                location,
                workspaceGroupsWithMetadata: location.workspace_groups,
                queryParams,
            });

            location.isMatch = location.isMatch && (result?.isMatch ?? true);
            location.filterMetadata = {
                ...location.filterMetadata,
                [filter.id]: result?.filterMetadata,
            };
        });
    }

    return {
        filteredLocations: locations,
        error: null,
    };
}
