"use client";

import { NoValue } from "@/common/domain/entities/typing/NoValue";
import { hasValue } from "@/common/utilities/hasValue";
import { smallLocationCardAlternative2Style } from "@/component-library/components/cards/location-cards/small-location-card-map/SmallLocationCardMap.css";
import {
    smallLocationCardContentHeaderTitleStyle,
    smallLocationCardContentStyle,
    smallLocationCardImageOverlayContentItemStyle,
    smallLocationCardImageOverlayContentStyle,
    smallLocationCardImageStyle,
    smallLocationCardLocationNameStyle,
    smallLocationCardOperatorNameStyle,
    smallLocationCardPricingStyle,
    smallLocationCardRatingStyle,
} from "@/component-library/components/cards/location-cards/small-location-card-shared-styles.css";
import { ConvertedCurrencyComponent } from "@/component-library/components/currency/converted-currency/ConvertedCurrencyComponent";
import Tooltip from "@/component-library/components/feedback/tooltip/Tooltip";
import MapMarkerIcon from "@/component-library/components/icons/MapMarkerIcon";
import Separator from "@/component-library/components/layout/separator/Separator";
import RemoteImage from "@/component-library/components/media/remote-image/RemoteImage";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { skeletonStyle } from "@/component-library/components/skeleton-style.css";
import { DealTag } from "@/component-library/components/text/tags/deal-tag/DealTag";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { theme } from "@/component-library/themes/theme.css";
import { TPictureSchema } from "@/features/host-locations/domain/entities/schemas/PictureSchema";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import React, { HTMLAttributes, ReactNode } from "react";

interface SmallLocationCardAlternative2Props
    extends HTMLAttributes<HTMLDivElement> {
    options: {
        cta?: ReactNode;
        dealLabel?: ReactNode;
        locationDetails: {
            name: string;
            operator: {
                name: string | NoValue;
            };
            currency: {
                code: string;
            };
        };
        picture: {
            pictureData: TPictureSchema | NoValue;
            pictureIsLoading?: boolean;
        };
        imageOverlays: {
            rating: string | NoValue;
            secondaryBlock?: ReactNode | NoValue;
        };
        pricing: {
            totalPriceWithoutVat: number | NoValue;
            hourlyPriceWithoutVat: number | NoValue;
            pricingIsLoading?: boolean;
        };
    };
}

const SmallLocationCardMap: React.FC<SmallLocationCardAlternative2Props> = ({
    options,
}) => {
    return (
        <div className={smallLocationCardAlternative2Style}>
            <div className={smallLocationCardImageStyle}>
                <RemoteImage
                    fallbackCaption={"Image of the location"}
                    imageResolution={{
                        widthInPx: 400,
                        heightInPx: 300,
                    }}
                    pictureData={options.picture.pictureData ?? undefined}
                    keepInLoadingState={
                        options.picture.pictureIsLoading ?? false
                    }
                    quality={100}
                />
                {options.dealLabel && (
                    <DealTag
                        style={assignInlineVars({
                            position: `absolute`,
                            top: `0.5rem`,
                            left: `0.5rem`,
                        })}
                    >
                        <Tooltip
                            tooltip={`This location has a deal! Open the page to find out more.`}
                        >
                            {options.dealLabel}
                        </Tooltip>
                    </DealTag>
                )}
                <div className={smallLocationCardImageOverlayContentStyle}>
                    <div className={smallLocationCardRatingStyle}>
                        {hasValue(options.imageOverlays.rating)
                            ? options.imageOverlays.rating
                            : "–"}
                    </div>
                    {options.imageOverlays.secondaryBlock && (
                        <div
                            className={
                                smallLocationCardImageOverlayContentItemStyle
                            }
                        >
                            {options.imageOverlays.secondaryBlock}
                        </div>
                    )}
                </div>
            </div>
            <div className={smallLocationCardContentStyle}>
                <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={Magnitudes.spacingInRem.xxs}
                >
                    <div className={smallLocationCardContentHeaderTitleStyle}>
                        {options.locationDetails.operator.name && (
                            <div className={smallLocationCardOperatorNameStyle}>
                                {options.locationDetails.operator.name}
                            </div>
                        )}
                        <Flex
                            direction="row"
                            alignItems="center"
                            gap={Magnitudes.spacingInRem.xxxs}
                            style={assignInlineVars({
                                overflow: `hidden`,

                                marginTop: hasValue(
                                    options.locationDetails.operator.name
                                )
                                    ? `-0.25em`
                                    : undefined,

                                whiteSpace: `nowrap`,
                            })}
                        >
                            <MapMarkerIcon
                                pathProps={{
                                    style: assignInlineVars({
                                        fill: theme.colors.semantic.interactive
                                            .default,
                                    }),
                                }}
                                style={assignInlineVars({
                                    flex: `0 0 auto`,
                                    display: `block`,
                                })}
                            />
                            <div className={smallLocationCardLocationNameStyle}>
                                {options.locationDetails.name}
                            </div>
                        </Flex>
                    </div>

                    <Flex
                        direction="row"
                        alignItems="stretch"
                        gap={Magnitudes.spacingInRem.xxxs}
                    >
                        <Separator
                            variant="verticalLight"
                            style={assignInlineVars({
                                flex: `0 0 auto`,

                                height: `auto`,
                            })}
                        />

                        {options.pricing.pricingIsLoading ?? false ? (
                            <div className={smallLocationCardPricingStyle}>
                                <div>Starting from</div>
                                <div className={skeletonStyle}>
                                    <VisuallyHidden>
                                        Total price is loading...
                                    </VisuallyHidden>
                                </div>
                                <div className={skeletonStyle}>
                                    <VisuallyHidden>
                                        Hourly price is loading...
                                    </VisuallyHidden>
                                </div>
                            </div>
                        ) : (
                            <div className={smallLocationCardPricingStyle}>
                                <div>Starting from</div>
                                {options.pricing.totalPriceWithoutVat && (
                                    <div>
                                        <VisuallyHidden>
                                            Total price is{" "}
                                        </VisuallyHidden>
                                        <ConvertedCurrencyComponent
                                            amount={
                                                options.pricing
                                                    .totalPriceWithoutVat
                                            }
                                            currency={
                                                options.locationDetails.currency
                                                    .code
                                            }
                                        />
                                    </div>
                                )}
                                {!options.pricing.totalPriceWithoutVat &&
                                    hasValue(
                                        options.pricing.hourlyPriceWithoutVat
                                    ) && (
                                        <div>
                                            <VisuallyHidden>
                                                Hourly price is{" "}
                                            </VisuallyHidden>
                                            <ConvertedCurrencyComponent
                                                amount={
                                                    options.pricing
                                                        .hourlyPriceWithoutVat
                                                }
                                                currency={
                                                    options.locationDetails
                                                        .currency.code
                                                }
                                            />
                                        </div>
                                    )}
                            </div>
                        )}
                    </Flex>
                </Flex>
                {options.cta}
            </div>
        </div>
    );
};

export default SmallLocationCardMap;
