import { reportInfo } from "@/common/application/debug";
import { NetworkEndpoints } from "@/configs/NetworkEndpoints";
import { NetworkRequests } from "@/features/backend/application/network-requests";
import { GeographicCoordinate } from "@/features/host-locations/domain/entities/GeographicCoordinate";
import { GeographicLocation } from "@/features/host-locations/domain/entities/GeographicLocation";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Could not find a declaration file for module '@turf/turf'. This seems to be a bug.
import { feature } from "@turf/turf";
import { AxiosResponse } from "axios";
import { FeatureCollection } from "geojson";

/**
 * Creates a [GeographicLocation] from a diversity of input. The resulting
 * instance confirms to the [GeographicLocation] interface
 * @param rawLocationAsString is the raw location input passed as a param
 * and to be attempted to be transformed into a [GeographicLocation].
 * @return
 * @throws {InvalidGeographicLocationError}
 */
export const convertParamToGeographicLocation = async (
    rawLocationAsString: string | undefined
): Promise<
    | {
          coordinates: GeographicLocation;
          bbox?: number[];
          zoom: number;
      }
    | undefined
> => {
    if (!rawLocationAsString || rawLocationAsString.trim().length === 0) {
        // [rawLocationAsString] does not contain any data that can be processed
        return undefined;
    }

    // Coordinates in the URL param are of pattern "coordinate_x_y". Here, we
    // split the string on
    const rawLocationInputStringComponents = rawLocationAsString.split("_");

    try {
        if (
            rawLocationInputStringComponents.at(0)?.toLowerCase() ===
            "coordinate"
        ) {
            const zoomString = rawLocationInputStringComponents.at(3) || "10";
            // The data indicates that it is a coordinate
            return {
                coordinates: new GeographicCoordinate(
                    ...rawLocationInputStringComponents.slice(1, 3)
                ),
                zoom: parseFloat(zoomString),
            };
        } else {
            const locationName = rawLocationInputStringComponents.join(" ");
            // Fetch the location's coordinates from the Mapbox API.
            const geoCodedLocation: AxiosResponse<FeatureCollection> =
                await NetworkRequests.networkGet({
                    endpoint: NetworkEndpoints.geocodeUrl(locationName),
                });

            reportInfo("GeoCoded Location", geoCodedLocation.data);
            // Convert the coordinates to a GeoJSON Feature.
            const firstFeature = feature(
                geoCodedLocation.data.features[0].geometry,
                {},
                {
                    id: geoCodedLocation.data.features[0].id,
                    bbox: geoCodedLocation.data.features[0].properties?.bbox,
                }
            );
            // Return the coordinates and the zoom level.
            return {
                coordinates: new GeographicCoordinate(
                    firstFeature.geometry.coordinates[1],
                    firstFeature.geometry.coordinates[0]
                ),
                bbox: firstFeature.bbox,
                zoom: 12,
            };
        }
    } catch (error) {
        return undefined;
    }
};
