import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { theme } from "@/component-library/themes/theme.css";
import { stylex } from "@/component-library/utilities/stylex";
import { FC, ReactNode } from "react";
import { Tag } from "../tag/Tag";
import { faPercent } from "@fortawesome/free-solid-svg-icons";

interface DealTagProps {
    children: ReactNode | ReactNode[];
    style?: React.CSSProperties;
}

export const DealTag: FC<DealTagProps> = ({ children, ...restProps }) => {
    return (
        <Tag
            {...restProps}
            style={stylex(restProps.style, {
                backgroundColor: theme.colors.semantic.interactive.default,
                color: theme.colors.basic.white,
                fontVariationSettings: `"wght" 500`,
                fontWeight: 500,
            })}
        >
            <FontAwesomeIcon icon={faPercent} />
            {children}
        </Tag>
    );
};
