"use client";

import { hasValue } from "@/common/utilities/hasValue";
import { stylex } from "@/component-library/utilities/stylex";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import React, {
    ElementType,
    HTMLProps,
    JSX,
    ReactNode,
    useLayoutEffect,
    useState,
} from "react";

interface HiddenProps<TAs extends keyof JSX.IntrinsicElements>
    extends HTMLProps<TAs> {
    children?: ReactNode;
    isShowing: boolean;
    as?: TAs;
}

/**
 * Renders content in the HTML with `display="none"`. In client, conditionally shows the content
 * based on the `isShowing` prop. This can be used to avoid hydration errors due to SSR, e.g.
 * due to `useMediaQuery` hook. Approach suggested by
 * https://www.reddit.com/r/nextjs/comments/n98d8s/usemediaqueryhook_that_actually_works_with_ssr/.
 * @param children
 * @param isShowing - Content is always rendered but initially hidden. Client-side, content is
 * conditionally rendered based on this prop.
 * @param as - Type of HTML element to render the children in.
 * @constructor
 */
const Hidden = <TAs extends keyof JSX.IntrinsicElements>({
    children,
    isShowing: _isShowing,
    as = "div" as TAs,
    style,
    ...restProps
}: HiddenProps<TAs>) => {
    const [isShowing, setIsShowing] = useState<boolean | null>(null);

    const As = as as ElementType;

    useLayoutEffect(() => {
        setIsShowing(_isShowing);
    }, [_isShowing]);

    return (
        <As
            style={stylex(
                assignInlineVars({
                    display:
                        !hasValue(isShowing) || !isShowing ? "none" : undefined,
                }),
                style
            )}
            {...restProps}
        >
            {children}
        </As>
    );
};

export default Hidden;
