import React, { FC } from "react";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import Link from "next/link";
import { linkStyle } from "@/app/(main)/discover/(layout)/(components)/(discover-app-bar)/(links)/AppBarLinksStyle.css";

export const AppBarLinks: FC = () => {
    return (
        <Flex
            gap={Magnitudes.spacingInRem.xxxxl}
            direction={"row"}
            alignItems={"center"}
            style={{
                marginLeft: "auto",
            }}
        >
            <Link href={"https://wezoo.com/how-it-works"} className={linkStyle}>
                How it works
            </Link>
            <Link href={"https://wezoo.com/insights"} className={linkStyle}>
                Insights
            </Link>
            <Link href={"https://wezoo.com/about-us"} className={linkStyle}>
                About us
            </Link>
            <Link href={"https://wezoo.com/contact"} className={linkStyle}>
                Contact
            </Link>
            <Link
                href={"https://wezoo.com/become-a-host"}
                className={linkStyle}
            >
                Become a host
            </Link>
            {/*<Suspense>*/}
            {/*    <TrustBoxContainer />*/}
            {/*</Suspense>*/}
        </Flex>
    );
};
