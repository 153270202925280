import Button from "@/component-library/components/buttons/button/Button";
import { smallLocationCardAlternative1Style } from "@/component-library/components/cards/location-cards/small-location-card-alternative-1/SmallLocationCardAlternative1.css";
import {
    smallLocationCardContentHeaderStyle,
    smallLocationCardContentHeaderTitleStyle,
    smallLocationCardContentStyle,
    smallLocationCardCTAStyle,
    smallLocationCardImageStyle,
    smallLocationCardLocationNameStyle,
    smallLocationCardOperatorNameStyle,
    smallLocationCardPricingStyle,
    smallLocationCardRatingStyle,
} from "@/component-library/components/cards/location-cards/small-location-card-shared-styles.css";
import MapMarkerIcon from "@/component-library/components/icons/MapMarkerIcon";
import Separator from "@/component-library/components/layout/separator/Separator";
import RemoteImage from "@/component-library/components/media/remote-image/RemoteImage";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { skeletonStyle } from "@/component-library/components/skeleton-style.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import Link from "next/link";
import React from "react";

interface SmallLocationCardAlternative1SkeletonProps {}

const SmallLocationCardAlternative1Skeleton: React.FC<
    SmallLocationCardAlternative1SkeletonProps
> = () => {
    return (
        <div className={clsx(smallLocationCardAlternative1Style)}>
            <div className={smallLocationCardImageStyle}>
                <RemoteImage
                    fallbackCaption={"Image of the location"}
                    imageResolution={{
                        widthInPx: 400,
                        heightInPx: 300,
                    }}
                    pictureData={undefined}
                    keepInLoadingState
                />
            </div>
            <div className={smallLocationCardContentStyle}>
                <div className={smallLocationCardContentHeaderStyle}>
                    <div
                        className={clsx(
                            skeletonStyle,
                            smallLocationCardRatingStyle
                        )}
                    >
                        <VisuallyHidden>Loading rating...</VisuallyHidden>
                    </div>
                    <div className={smallLocationCardContentHeaderTitleStyle}>
                        <div
                            className={clsx(
                                skeletonStyle,
                                smallLocationCardOperatorNameStyle
                            )}
                        >
                            Loading operator...
                        </div>
                        <Flex
                            direction="row"
                            alignItems="center"
                            gap={Magnitudes.spacingInRem.xxxs}
                            style={assignInlineVars({
                                overflow: `hidden`,

                                marginTop: `-0.25em`,

                                whiteSpace: `nowrap`,
                            })}
                            className={skeletonStyle}
                        >
                            <MapMarkerIcon
                                pathProps={{
                                    style: assignInlineVars({
                                        fill: "transparent",
                                    }),
                                }}
                                style={assignInlineVars({
                                    flex: `1 0 auto`,
                                    display: `block`,
                                })}
                            />
                            <div
                                className={clsx(
                                    smallLocationCardLocationNameStyle
                                )}
                                style={assignInlineVars({
                                    color: "transparent",
                                })}
                            >
                                Loading location name...
                            </div>
                        </Flex>
                    </div>
                </div>
                <Separator variant="light" />
                <Flex
                    direction="row"
                    justifyContent="stretch"
                    alignItems="stretch"
                    gap={Magnitudes.spacingInRem.xxs}
                    style={assignInlineVars({
                        minHeight: `3.75em`,
                    })}
                >
                    <Button
                        as={Link}
                        href={"#"}
                        className={clsx(
                            skeletonStyle,
                            smallLocationCardCTAStyle
                        )}
                    >
                        Loading rooms...
                    </Button>
                    <div
                        className={clsx(
                            skeletonStyle,
                            smallLocationCardPricingStyle
                        )}
                    >
                        <div
                            style={assignInlineVars({
                                width: `60px`,
                                color: "transparent",
                            })}
                        >
                            <VisuallyHidden>Loading total</VisuallyHidden>
                            price...
                        </div>
                        <div
                            style={assignInlineVars({
                                width: `50px`,
                                color: "transparent",
                            })}
                        >
                            <VisuallyHidden>Loading hourly</VisuallyHidden>
                            price...
                        </div>
                    </div>
                </Flex>
            </div>
        </div>
    );
};

export default SmallLocationCardAlternative1Skeleton;
