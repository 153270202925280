import axios, {
    AxiosRequestConfig,
    AxiosRequestHeaders,
    ResponseType,
} from "axios";
import useSWR from "swr";

/**
 * Request params required by all the requests.
 */
interface SharedRequestConfig {
    endpoint: string | string[];
    headers?: AxiosRequestHeaders;
    responseType?: ResponseType;
}

/**
 * Additional information needed for a GET request.
 */
interface GetRequestConfig extends SharedRequestConfig {
    params?: any;
}

/**
 * Additional information needed for a POST request.
 */
interface PostRequestConfig extends SharedRequestConfig {
    data: any;
    params?: any;
}

export class NetworkRequests {
    /**
     * Makes an SWR network request.
     * @param config - Configures the request.
     */
    static readonly useNetworkRequestSWR = (config: AxiosRequestConfig) => {
        return useSWR(config, this.swrFetcher);
    };

    /**
     * Makes a raw network GET request without any data caching or revalidation.
     * @param endpoint
     * @param headers - Optionally configure the headers.
     * @param responseType - Optionally configure the type of data expected.
     * @param data - Data to be sent with the request.
     * @param params - Query parameters.
     */
    static readonly createNetworkGetCall = ({
        endpoint,
        headers,
        responseType = "json",
        params,
    }: GetRequestConfig) => {
        return () => {
            return this.makeNetworkRequest({
                method: "get",
                url: Array.isArray(endpoint) ? endpoint.join("/") : endpoint,
                params,
                headers,
                responseType,
            });
        };
    };

    static readonly networkGet = (config: GetRequestConfig) =>
        this.createNetworkGetCall(config)();

    /**
     * SWR network GET request.
     * @param endpoint
     * @param headers - Optionally configure the headers.
     * @param responseType - Optionally configure the type of data expected.
     * @param data - Data to be sent with the request.
     */
    static readonly useNetworkGetSWR = ({
        endpoint,
        headers,
        responseType = "json",
    }: GetRequestConfig) => {
        return this.useNetworkRequestSWR({
            method: "get",
            url: Array.isArray(endpoint) ? endpoint.join("/") : endpoint,
            headers,
            responseType,
        });
    };

    /**
     * Makes a raw network POST request without any data caching or
     * revalidation.
     * @param endpoint
     * @param headers - Optionally configure the headers.
     * @param responseType - Optionally configure the type of data expected.
     * @param data - Data to be sent with the request.
     */
    static readonly createNetworkPostCall = ({
        endpoint,
        headers,
        responseType = "json",
        data,
        params,
    }: PostRequestConfig) => {
        return () =>
            this.makeNetworkRequest({
                method: "post",
                url: Array.isArray(endpoint) ? endpoint.join("/") : endpoint,
                headers,
                responseType,
                data,
                params,
            });
    };

    /**
     * Makes a raw network DELETE request without any data caching or
     * revalidation.
     * @param endpoint
     * @param headers - Optionally configure the headers.
     * @param responseType - Optionally configure the type of data expected.
     * @param params - Query parameters.
     */
    static readonly createNetworkDeleteCall = ({
        endpoint,
        headers,
        responseType = "json",
    }: SharedRequestConfig) => {
        return () =>
            this.makeNetworkRequest({
                method: "delete",
                url: Array.isArray(endpoint) ? endpoint.join("/") : endpoint,
                headers,
                responseType,
            });
    };

    /**
     * SWR network POST request.
     * @param endpoint
     * @param headers - Optionally configure the headers.
     * @param responseType - Optionally configure the type of data expected.
     * @param data - Data to be sent with the request.
     */
    static readonly useNetworkPostSWR = ({
        endpoint,
        headers,
        responseType = "json",
        data,
        params,
    }: PostRequestConfig) => {
        return this.useNetworkRequestSWR({
            method: "post",
            url: Array.isArray(endpoint) ? endpoint.join("/") : endpoint,
            headers,
            responseType,
            data,
            params,
        });
    };

    static readonly networkPost = (config: PostRequestConfig) =>
        this.createNetworkPostCall(config)();

    static readonly networkDelete = (config: SharedRequestConfig) =>
        this.createNetworkDeleteCall(config)();

    /**
     * Makes a network request with axios.
     * @param config
     */
    private static readonly makeNetworkRequest = (
        config: AxiosRequestConfig
    ) => {
        return axios(config);
    };

    private static readonly swrFetcher = async (
        requestConfig: AxiosRequestConfig
    ) => {
        return this.makeNetworkRequest(requestConfig);
    };
}
