import { NoValue } from "@/common/domain/entities/typing/NoValue";
import { hasValue } from "@/common/utilities/hasValue";
import Button from "@/component-library/components/buttons/button/Button";
import { smallLocationCardAlternative1Style } from "@/component-library/components/cards/location-cards/small-location-card-alternative-1/SmallLocationCardAlternative1.css";
import {
    smallLocationCardContentHeaderStyle,
    smallLocationCardContentHeaderTitleStyle,
    smallLocationCardContentStyle,
    smallLocationCardCTAStyle,
    smallLocationCardImageStyle,
    smallLocationCardLocationNameStyle,
    smallLocationCardOperatorNameStyle,
    smallLocationCardPricingStyle,
    smallLocationCardRatingStyle,
} from "@/component-library/components/cards/location-cards/small-location-card-shared-styles.css";
import { ConvertedCurrencyComponent } from "@/component-library/components/currency/converted-currency/ConvertedCurrencyComponent";
import Tooltip from "@/component-library/components/feedback/tooltip/Tooltip";
import MapMarkerIcon from "@/component-library/components/icons/MapMarkerIcon";
import Separator from "@/component-library/components/layout/separator/Separator";
import RemoteImage from "@/component-library/components/media/remote-image/RemoteImage";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { DealTag } from "@/component-library/components/text/tags/deal-tag/DealTag";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { theme } from "@/component-library/themes/theme.css";
import { TPictureSchema } from "@/features/host-locations/domain/entities/schemas/PictureSchema";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import Link from "next/link";
import React, { HTMLAttributes, ReactNode } from "react";

interface SmallLocationCardAlternative1Props
    extends HTMLAttributes<HTMLDivElement> {
    options: {
        locationPageHref: string;
        ctaLabel: ReactNode;
        dealLabel?: ReactNode;
        locationDetails: {
            name: string;
            operator: {
                name: string | NoValue;
            };
            currency: {
                code: string;
            };
        };
        picture: {
            pictureData: TPictureSchema | NoValue;
            pictureIsLoading?: boolean;
        };
        rating: string | NoValue;
        pricing: {
            totalPriceWithoutVat: number | NoValue;
            hourlyPriceWithoutVat: number;
        };
        priority?: boolean;
    };
}

const SmallLocationCardAlternative1: React.FC<
    SmallLocationCardAlternative1Props
> = ({ options, ...restProps }) => {
    return (
        <div
            className={clsx(smallLocationCardAlternative1Style)}
            {...restProps}
        >
            <a
                href={options.locationPageHref}
                target="_blank"
                className={smallLocationCardImageStyle}
            >
                <RemoteImage
                    fallbackCaption={"Image of the location"}
                    imageResolution={{
                        widthInPx: 400,
                        heightInPx: 300,
                    }}
                    pictureData={options.picture.pictureData ?? undefined}
                    keepInLoadingState={
                        options.picture.pictureIsLoading ?? false
                    }
                    priority={options.priority}
                    quality={100}
                />
                {options.dealLabel && (
                    <DealTag
                        style={assignInlineVars({
                            position: `absolute`,
                            top: `0.5rem`,
                            left: `0.5rem`,
                        })}
                    >
                        <Tooltip
                            tooltip={`This location has a deal! Open the page to find out more.`}
                        >
                            {options.dealLabel}
                        </Tooltip>
                    </DealTag>
                )}
            </a>
            <div className={smallLocationCardContentStyle}>
                <Link
                    href={options.locationPageHref}
                    target="_blank"
                    style={assignInlineVars({
                        textDecoration: `none`,
                        overflow: `hidden`,
                    })}
                >
                    <div className={smallLocationCardContentHeaderStyle}>
                        <div className={smallLocationCardRatingStyle}>
                            {hasValue(options.rating) ? options.rating : "–"}
                        </div>
                        <div
                            className={smallLocationCardContentHeaderTitleStyle}
                        >
                            {options.locationDetails.operator.name && (
                                <div
                                    className={
                                        smallLocationCardOperatorNameStyle
                                    }
                                >
                                    {options.locationDetails.operator.name}
                                </div>
                            )}
                            <Flex
                                direction="row"
                                alignItems="center"
                                gap={Magnitudes.spacingInRem.xxxs}
                                style={assignInlineVars({
                                    overflow: `hidden`,

                                    marginTop: hasValue(
                                        options.locationDetails.operator.name
                                    )
                                        ? `-0.25em`
                                        : undefined,

                                    whiteSpace: `nowrap`,
                                })}
                            >
                                <MapMarkerIcon
                                    pathProps={{
                                        style: assignInlineVars({
                                            fill: theme.colors.semantic
                                                .interactive.default,
                                        }),
                                    }}
                                    style={assignInlineVars({
                                        flex: `0 0 auto`,
                                        display: `block`,
                                    })}
                                />
                                <div
                                    className={
                                        smallLocationCardLocationNameStyle
                                    }
                                >
                                    {options.locationDetails.name}
                                </div>
                            </Flex>
                        </div>
                    </div>
                </Link>
                <Separator variant="light" />
                <Flex
                    direction="row"
                    justifyContent="stretch"
                    alignItems="stretch"
                    gap={Magnitudes.spacingInRem.xxs}
                    style={assignInlineVars({
                        minHeight: `3.75em`,
                    })}
                >
                    <Button
                        as={Link}
                        href={options.locationPageHref}
                        target="_blank"
                        className={smallLocationCardCTAStyle}
                    >
                        {options.ctaLabel}
                    </Button>
                    <div className={smallLocationCardPricingStyle}>
                        <div>Starting from</div>
                        {options.pricing.totalPriceWithoutVat ? (
                            <div>
                                <VisuallyHidden>Total price is </VisuallyHidden>
                                <ConvertedCurrencyComponent
                                    amount={
                                        options.pricing.totalPriceWithoutVat
                                    }
                                    currency={
                                        options.locationDetails.currency.code
                                    }
                                />
                            </div>
                        ) : (
                            <div>
                                <VisuallyHidden>
                                    The minimum price is{" "}
                                </VisuallyHidden>
                                <ConvertedCurrencyComponent
                                    amount={
                                        options.pricing.hourlyPriceWithoutVat
                                    }
                                    currency={
                                        options.locationDetails.currency.code
                                    }
                                />
                            </div>
                        )}
                    </div>
                </Flex>
            </div>
        </div>
    );
};

export default SmallLocationCardAlternative1;
