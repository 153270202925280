import { mapQueryStateViewKey } from "@/app/(main)/discover/[[...params]]/(components)/(map)/mapQueryStateKeys";
import { discoverMapListFilterSidebarWidthInPx } from "@/app/(main)/discover/[[...params]]/(components)/discover-map-constants";
import { discoverViewParser } from "@/app/(main)/discover/[[...params]]/(components)/DiscoverViewSwitch";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { theme } from "@/component-library/themes/theme.css";
import FacilityFilterSideBarComponent from "@/features/filtering/filters/facility-filter/FacilityFilterSideBarComponent";
import PricingFilterSideBarComponent from "@/features/filtering/filters/pricing-filter/PricingFilterSideBarComponent";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { useQueryState } from "nuqs";
import React, { FC } from "react";
import MapLinkCard from "./MapLinkCard";

interface SecondaryFilterListProps {
    showMapLink?: boolean;
}

export const SecondaryFilterList: FC<SecondaryFilterListProps> = ({
    showMapLink,
}) => {
    const [, setView] = useQueryState(mapQueryStateViewKey, discoverViewParser);
    return (
        <Flex
            direction={"column"}
            alignItems="stretch"
            style={assignInlineVars({
                minWidth: `${discoverMapListFilterSidebarWidthInPx}px`,
                width: `${discoverMapListFilterSidebarWidthInPx}px`,
                height: "100%",
                minHeight: "100%",
                borderRight: `${Magnitudes.borderWidthInPx.m}px solid ${theme.colors.surface.outline}`,
                paddingTop: `${Magnitudes.spacingInRem.xxl}rem`,
                overflowY: "scroll",
            })}
        >
            {showMapLink && <MapLinkCard onClick={() => setView("map")} />}
            <PricingFilterSideBarComponent />
            <FacilityFilterSideBarComponent />
        </Flex>
    );
};
