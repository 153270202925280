"use client";

import { discoverAppBarStyle } from "@/app/(main)/discover/(layout)/(components)/(discover-app-bar)/DiscoverAppBar.css";
import { DiscoverAppBarMenuComponent } from "@/app/(main)/discover/(layout)/(components)/(discover-app-bar)/DiscoverAppBarMenuComponent";
import { DiscoverMapAppBarSearchComponentWrapper } from "@/app/(main)/discover/[[...params]]/(components)/(map)/DiscoverMapAppBarSearchComponentWrapper";
import { ReusableMapImperativeHandle } from "@/component-library/components/map-reusable/ReusableMap";
import { clsx } from "clsx";
import { FC, MutableRefObject } from "react";

type DiscoverMapHeaderProps = {
    mapRef: MutableRefObject<ReusableMapImperativeHandle | null>;
};

export const DiscoverMapAppBar: FC<DiscoverMapHeaderProps> = ({ mapRef }) => {
    return (
        <div className={clsx(discoverAppBarStyle)}>
            <DiscoverAppBarMenuComponent />
            <DiscoverMapAppBarSearchComponentWrapper mapRef={mapRef} />
        </div>
    );
};
