import { NetworkEndpoints } from "@/configs/NetworkEndpoints";
import { getHostLocationsFetcher } from "@/features/host-locations/application/getHostLocationsFetcher";
import {
    HostLocationFullInformationSchema,
    THostLocationFullInformationSchema,
} from "@/features/host-locations/domain/entities/schemas/HostLocationFullInformationSchema";
// import { excludeKeys } from "filter-obj";

export const getSingleHostLocation = async (props: {
    hostLocationId: string;
    queryParams: Record<string, string>;
}): Promise<THostLocationFullInformationSchema | null> => {
    return await getHostLocationsFetcher({
        ...props,
        endpoint: NetworkEndpoints.location(props.hostLocationId),
        locationSchema: HostLocationFullInformationSchema,
        applyFilters: false,
    });
};
